import { SimulationLesson } from '@db/collections';
import { pipe, 운전선생허용수업 } from 'shared-values';

const isLessonTraining = (lesson: Lesson) => {
  return lesson instanceof SimulationLesson
    ? lesson.registType === 'training'
    : lesson.code.includes('TRAINING');
};

// 이전 화면에 따른 필터링
export const filterLessonsBySelectedRegistType = (
  lessons: Lesson[],
  selectedRegistType: RegistType,
) => {
  const isTraining = selectedRegistType === 'training';

  return isTraining
    ? lessons.filter((lesson) => isLessonTraining(lesson))
    : lessons.filter((lesson) => !isLessonTraining(lesson));
};

// 운전선생허용수업 이외의 수업은 제거
const removeLessons = (lessons: Lesson[]) =>
  lessons.filter((lesson) => 운전선생허용수업.includes(lesson.code));

export const filterLessons = (lessons: Lesson[], selectedRegistType: RegistType) =>
  pipe<Lesson>(
    (lessons) => removeLessons(lessons),
    (lessons) => filterLessonsBySelectedRegistType(lessons, selectedRegistType),
  )(lessons);
