import { useEffect, useState } from 'react';

import { Input, NewBoxButton, Spacer } from 'design-system';

import { useApplicationFunnel } from '@templates/Application/hooks';

import { formatBirthday, formatPhoneNumber } from './utils';

export const VisitorInfo = () => {
  const [isVisitorInfoOpen, setIsVisitorInfoOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [birthday, setBirthday] = useState<string>('');
  const [phoneNum, setPhoneNum] = useState<string>('');

  const { setVisitorInfo } = useApplicationFunnel();

  const nameRegex = /^[A-Za-z가-힣]+$/;
  const birthdayRegex = /^\d{4}-\d{2}-\d{2}$/;
  const phoneNumRegex = /^010-\d{4}-\d{4}$/;

  const handleBirthdayChange = (value: string): void => {
    const formattedBirthday = formatBirthday(value);
    setBirthday(formattedBirthday);
  };

  const handlePhoneInputChange = (value: string): void => {
    const formattedNumber = formatPhoneNumber(value);
    setPhoneNum(formattedNumber);
  };

  useEffect(() => {
    if (nameRegex.test(name) && birthdayRegex.test(birthday) && phoneNumRegex.test(phoneNum)) {
      // 세 개의 인풋필드가 모두 조건을 만족했을 경우 zustandStore에 저장
      setVisitorInfo({ name: name, birthday: birthday, phoneNum: phoneNum });
    } else {
      /**
       * 인풋필드의 값이 다시 조건을 벗어난 경우를 대비한 else 로직.
       * 바로 값을 초기화한다.
       * 다만 계속해서 정규식을 만족사키지 못하는 경우에 계속해서 Effect가 작동할 텐데, 어떻게 해결하는게 좋을까요?  */
      setVisitorInfo({ name: '', birthday: '', phoneNum: '' });
    }
  }, [name, birthday, phoneNum]);

  return (
    <>
      {isVisitorInfoOpen ? (
        <>
          <Spacer className="h-18" />
          <hr className="border-new-gray-100 -mx-16 border" />
          <Spacer className="h-4" />
          <h4 className="text-new-Sub-Title text-new-gray-900">실제 방문자 정보</h4>
          <Spacer className="h-6" />
          <section className="flex flex-col gap-20">
            <Input
              label="이름"
              placeholder="실제 방문자 정보를 입력해주세요."
              inputType="text"
              type="box"
              helperText="이름을 정확히 입력해 주세요."
              inputValue={name}
              onInputChange={setName}
              isError={!nameRegex.test(name) && name !== ''}
            />
            <Input
              label="생년월일"
              placeholder="숫자만 입력해 주세요. 예시) 20221023"
              inputType="text"
              type="box"
              helperText="생년월일을 정확히 입력해 주세요."
              inputValue={birthday}
              onInputChange={handleBirthdayChange}
              isError={!birthdayRegex.test(birthday) && birthday !== ''}
            />
            <Input
              label="전화번호"
              placeholder="숫자만 입력해 주세요. 예시) 01012345678"
              inputType="tel"
              type="box"
              helperText="휴대폰 번호를 정확히 입력해 주세요."
              inputValue={phoneNum}
              onInputChange={handlePhoneInputChange}
              isError={!phoneNumRegex.test(phoneNum) && phoneNum !== ''}
            />
          </section>
        </>
      ) : (
        <NewBoxButton
          label="예약자와 방문자가 다를 경우 정보를 추가해 주세요."
          icon="plus-outlined"
          iconPosition="left"
          styles="outlined"
          size="medium"
          fill
          className="fill-new-gray-400"
          onClick={() => setIsVisitorInfoOpen(true)}
        />
      )}
    </>
  );
};
