import { useEffect } from 'react';

import {
  sortAcademyLessons,
  sortSimulationLessons,
} from '@templates/Academy/components/AcademyInformation/components/Lesson/SimulationLessons/sort-lessons';
import { useApplicationFunnel } from '@templates/Application/hooks';

import { filterLessons, generateBlocksWithLessons } from './caculations';

export const useGenerateFirstSection = () => {
  const { academy, selectedRegistType, sections, dispatch } =
    useApplicationFunnel<'LessonsSelect'>();

  const { lessons, isSimulation } = academy;

  useEffect(() => {
    if (sections.length > 0) return;

    const filteredLessons = filterLessons(lessons, selectedRegistType);

    const sortedLessons = isSimulation
      ? sortSimulationLessons(filteredLessons as SimulationLesson[])
      : sortAcademyLessons(filteredLessons);

    if (selectedRegistType === 'training' && !isSimulation) {
      dispatch({
        action: 'add-section',
        payload: {
          section: {
            title: '수업 종류',
            blocks: sortedLessons.map((lesson) => ({
              label: lesson.label,
              subLabel: lesson.code.includes('TRAINING') ? lesson.renderPrice : '',
              isPopular: false,
              isNew: false,
              clickActions: [
                {
                  action: 'add-data',
                  payload: { lessonInfo: lesson.lessonInfo },
                },
              ],
            })),
            clickedBlockLabel: '',
          },
        },
      });
      return;
    }

    const blocks = generateBlocksWithLessons(sortedLessons, isSimulation);

    dispatch({
      action: 'add-section',
      payload: {
        section: {
          title: '운전 면허 종류',
          blocks,
          clickedBlockLabel: '',
        },
      },
    });
  }, []);
};
