import { Spacer } from 'design-system';

import { PriceBox } from './components';

export const Confirm = () => {
  return (
    <>
      <Spacer className='h-32' />
      <PriceBox />
      <Spacer className='h-32' />
    </>
  );
};
