import { useEffect, useRef } from 'react';

import { useOverlay } from 'design-system';
import { cn } from 'tailwind-config';

import { FormStyle } from '@templates/Application/components';
import { useApplicationFunnel, useGetLessonByQuerystring } from '@templates/Application/hooks';

import { Section } from './LessonsSelect.store';
import { attatchDefaultActions } from './caculations';
import { Section as SectionUI } from './components';
import { Block as BlockUI } from './components/Block';
import { OneLargeDialog } from './components/OneLargeDialog';
import { useGenerateFirstSection } from './hooks/use-generate-first-section';

function findSectionRecursive(
  section: Section,
  lessonCode: string,
  visitedSections: Set<Section> = new Set(),
): Section | undefined {
  for (const block of section.blocks) {
    for (const clickAction of block.clickActions) {
      if (
        clickAction.action === 'add-data' &&
        clickAction.payload.lessonInfo.lessonCode === lessonCode
      ) {
        return section;
      }
      if (clickAction.action === 'add-section') {
        const nestedSection = findSectionRecursive(
          clickAction.payload.section,
          lessonCode,
          visitedSections,
        );
        if (nestedSection) {
          return nestedSection;
        }
      }
    }
  }
  return undefined;
}
const useInitSelectedBlocks = () => {
  const isInitialized = useRef(false);
  const { sections, dispatch } = useApplicationFunnel<'LessonsSelect'>();
  const lesson = useGetLessonByQuerystring();

  useEffect(() => {
    if (isInitialized.current) return;
    if (!lesson || !sections.length) return;

    // 조건에 맞는 Block을 찾고, 그 동안의 모든 액션을 실행하면서
    // 조건을 충족하지 못하면 pop하는 함수
    const findAndProcessActions = (section: Section): boolean => {
      for (const block of section.blocks) {
        // 기본 액션을 실행
        attatchDefaultActions(block.clickActions, section, block).forEach(dispatch);

        for (const clickAction of block.clickActions) {
          // 조건을 만족하는 경우 탐색을 종료
          if (
            clickAction.action === 'add-data' &&
            clickAction.payload.lessonInfo.lessonCode === lesson.code
          ) {
            return true;
          }

          // add-section이 있는 경우, 하위 섹션을 재귀적으로 탐색
          if (clickAction.action === 'add-section') {
            const found = findAndProcessActions(clickAction.payload.section);
            if (found) {
              return true;
            } else {
              // 조건을 만족하지 못한 경우 pop하여 해당 섹션을 제거
              dispatch({ action: 'pop-section' });
            }
          }
        }
      }
      return false;
    };

    // 최상위 섹션 배열에서 시작하여 조건을 만족하는 Block을 찾음
    sections.some((section) => findAndProcessActions(section));
    isInitialized.current = true; // 초기화 완료 표시
  }, [lesson, sections, dispatch]);
};

export const LessonsSelect = () => {
  const { sections, dispatch, selectedRegistType } = useApplicationFunnel<'LessonsSelect'>();

  useGenerateFirstSection();
  useInitSelectedBlocks();

  const overlay = useOverlay();

  return (
    <FormStyle
      title="수업을 선택해 주세요."
      description={
        selectedRegistType === 'license'
          ? '필기시험에 합격했더라도, 학원 수업은 처음부터 들어야 해요.'
          : '현재는 자동변속기 차량 (승용차)만 예약 가능해요.'
      }
    >
      {sections.map((section, index) => {
        const { title, blocks, clickedBlockLabel, subTitle } = section;

        return (
          <SectionUI title={title} key={`section-${index}`} subTitle={subTitle}>
            {blocks.map((block, index) => {
              const { label, clickActions } = block;

              const isSelected = clickedBlockLabel === label;

              return (
                <BlockUI
                  key={index}
                  {...block}
                  state={isSelected ? 'selected' : 'active'}
                  className={cn(isSelected ? 'text-new-Body1-bold' : 'text-new-Body1-medium')}
                  onClick={() => {
                    if (isSelected) return;

                    if (block.label === '1종 대형') {
                      overlay.open(({ isOpen, close }) => {
                        return <OneLargeDialog isOpen={isOpen} close={close} />;
                      });
                    }
                    attatchDefaultActions(clickActions, section, block).forEach(dispatch);
                  }}
                />
              );
            })}
          </SectionUI>
        );
      })}
    </FormStyle>
  );
};
