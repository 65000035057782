import { StateCreator } from 'zustand';

import { ApplicationData } from '@templates/Application/hooks';

export interface DateSelectStore {
  selectedRange: SelectedRange | null;
  setSelectedRange: (selectedRange: SelectedRange | null) => void;
  selectedTime: SelectedTime | null;
  setSelectedTime: (selectedTime: SelectedTime | null) => void;
  resetTime: () => void;
}

export const dateSelectStore: StateCreator<ApplicationData, [], [], DateSelectStore> = (
  set,
  get,
) => ({
  selectedRange: null,
  setSelectedRange: (selectedRange: SelectedRange | null) => set({ selectedRange }),
  selectedTime: null,
  setSelectedTime: (selectedTime: SelectedTime | null) => set({ selectedTime }),
  resetTime: () => set({ selectedRange: null, selectedTime: null }),
});
