interface RefundBoxProps {
  is전액결제: boolean;
  is면허캠프: boolean;
}
export const RefundPolicyBanner = ({ is전액결제, is면허캠프 }: RefundBoxProps) => (
  <>
    <div className="bg-new-gray-50 w-full rounded-[16px] p-20">
      <div className="text-new-Body1-bold text-new-gray-900">환불정책</div>
      <ul className="text-new-Body2-medium py-6">
        {is면허캠프 ? (
          <>
            <li>
              <span className="text-new-gray-600">· 1일(24시간) ~ 도착, 노쇼 : </span>
              <span className="text-new-DTRed-400">상품 금액의 10% 공제 후 환불</span>
            </li>
            <li>
              <span className="text-new-gray-600">· 예약 신청 ~ 2일(48시간) : </span>
              <span className="text-new-DTRed-400">무료 환불</span>
            </li>
          </>
        ) : (
          <>
            <li>
              <span className="text-new-gray-600">· 1일(24시간) 전 ~ 도착, 노쇼 : </span>
              <span className="text-new-DTRed-400">
                {is전액결제 ? '환불수수료 5만원' : '환불 불가'}
              </span>
            </li>
            <li>
              <span className="text-new-gray-600">· 2일(48시간) 전 ~ 1일 : </span>
              <span className="text-new-DTRed-400">
                {is전액결제 ? '환불수수료 2.5만원' : '50% 환불'}
              </span>
            </li>
            <li>
              <span className="text-new-gray-600">· 예약신청날 ~ 2일 : </span>
              <span className="text-new-DTRed-400">100% 환불</span>
            </li>
          </>
        )}
      </ul>
      <span className="text-new-Caption1-medium text-new-gray-500">
        * 노쇼(No-Show) : 예약한 시간에 방문하지 않는 행위를 뜻합니다.
      </span>
    </div>
  </>
);
