import { ComponentProps, useEffect, useRef, useState } from 'react';

import { useGetUser } from '@db/collections';
import { Divider } from 'design-system';
import { isEmpty } from 'lodash-es';
import { useLocalStorage } from 'usehooks-ts';

import { useApplicationFunnel } from '@templates/Application/hooks';

import {
  Confirm,
  Payment,
  PurchaseAgreement,
  PurchaseBottom,
  ReservationInfo,
  ShuttleBusPreference,
  UserInfo,
} from './components';
import { PurchaseButton } from './components/PurchaseBottom/PurchaseButton';

export const Purchase = () => {
  const { user } = useGetUser();

  const { academy, lessonInfo } = useApplicationFunnel<'Purchase'>();
  const [isKeyInPurchase, setIsKeyInPurchase] = useState<boolean>(true);
  const [checked, setChecked] = useLocalStorage('purchase-agreement', {
    info: false,
    private: false,
    refund: false,
  });
  const [userVerified, setUserVerified] = useState<boolean>(false);

  const userInfoVerified = () => {
    if (!user) return;
    /** 본인인증여부, 핸드폰번호여부, 생년월일여부 확인해서 '본인인증' 단계 생략 */
    const hasPhoneNum = !isEmpty(user.phoneNum) && user.phoneNum.startsWith('010');
    const hasBirthday = !isEmpty(user.birthday);
    const hasName = !isEmpty(user.realName);

    if (hasPhoneNum && hasBirthday && hasName) {
      setUserVerified(true);
    }
  };

  const isAllChecked = checked.info && checked.private && checked.refund;
  // 순서대로 메시지의 우선순위가 결정 됨.
  const purchaseCondition = {
    verified: {
      value: Boolean(user ? user.isVerified || userVerified : true),
      message: '예약자 필수 정보가 필요해요.',
    },
    agreement: { value: isAllChecked, message: '결제 동의가 필요해요.' },
  };

  const refs = useRef<Record<keyof typeof purchaseCondition, HTMLDivElement | null>>(
    Object.keys(purchaseCondition).reduce((acc, key) => {
      acc[key as keyof typeof purchaseCondition] = null;
      return acc;
    }, {} as Record<keyof typeof purchaseCondition, HTMLDivElement | null>),
  );

  useEffect(() => {
    userInfoVerified();
  }, [user]);

  return (
    <>
      <Divider className="-mx-16 h-6">
        <ReservationInfo />
      </Divider>
      <Divider
        className="-mx-16 h-6"
        ref={(el) => {
          el && (refs.current.verified = el);
        }}
      >
        <UserInfo userVerified={userVerified} />
      </Divider>
      <Divider className="-mx-16 h-6">
        <ShuttleBusPreference />
      </Divider>
      <Divider className="-mx-16 h-6">
        <Confirm />
      </Divider>
      <Divider className="-mx-16 h-6">
        <Payment isKeyInPurchase={isKeyInPurchase} setIsKeyInPurchase={setIsKeyInPurchase} />
      </Divider>
      <Divider
        ref={(el) => {
          el && (refs.current.agreement = el);
        }}
      >
        <PurchaseAgreement
          checked={checked}
          isAllChecked={isAllChecked}
          setChecked={setChecked}
          academy={academy}
          lessonInfo={lessonInfo}
        />
      </Divider>
      <PurchaseBottom
        purchaseCondition={Object.keys(purchaseCondition).reduce((acc, key) => {
          acc[key as keyof typeof purchaseCondition] = {
            ...purchaseCondition[key as keyof typeof purchaseCondition],
            ref: refs.current[key as keyof typeof purchaseCondition],
          };
          return acc;
        }, {} as ComponentProps<typeof PurchaseButton>['purchaseCondition'])}
        isKeyInPurchase={isKeyInPurchase}
      />
    </>
  );
};
