import { SelectedRange } from 'database';
import { ko } from 'date-fns/locale';
import dynamic from 'next/dynamic';
import { addDays, formatDate } from 'shared-values';

import { FormStyle } from '@templates/Application/components';
import { useApplicationFunnel } from '@templates/Application/hooks';

import { CalenderHeader } from '../CalenderHeader';
import { SelectedScheduleIndicator } from '../SelectedScheduleIndicator';

const DatePicker = dynamic(() => import('react-datepicker'), {
  ssr: false,
});

const today = new Date();

const MIN_DATE = addDays(today, 2);

const MAX_DATE = addDays(today, 42);

export const SingleDateCalendar = () => {
  const {
    selectedRange: funnelSelectedRange,
    setSelectedRange: setFunnelSelectedRange,
    // isFromTeamEvent,
  } = useApplicationFunnel<'DateSelect'>();

  const handleDateSelect = (date: Date) => {
    setFunnelSelectedRange(new SelectedRange({ startDate: date, endDate: date }));
  };

  return (
    <FormStyle title="첫 방문을 원하는 날짜를 선택해 주세요.">
      <div className="rounded-md pt-16">
        <DatePicker
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <CalenderHeader
              date={date}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          highlightDates={funnelSelectedRange ? [funnelSelectedRange.startDate] : []}
          selectsStart
          dateFormatCalendar={'yy.MM'}
          locale={ko}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          startDate={today}
          onChange={handleDateSelect}
          inline
        />
      </div>
      {funnelSelectedRange && (
        <SelectedScheduleIndicator
          dateType="single"
          selectedSchedule={`${formatDate(funnelSelectedRange.startDate, 'YY.MM.DD')}`}
        />
      )}
    </FormStyle>
  );
};
