export const campDataById = [
  // {
  //   id: 'z5NDkv4bqsOVF5i0KEHy',
  //   academyName: '하예서부',
  //   academyLocation: '경기도 파주시 파주읍 파주리 249',
  //   dormName: '파주 게스트 하우스',
  //   dormLocation: '경기도 파주시 문산읍 사목로 223',
  //   title: `[서울 합정역 출발] 2박 3일 2종 보통 면허 취득 캠프`,
  //   subTitle: '(하예서부 자동차운전전문학원 / 2종 보통)',
  //   description: '(월,화,수) · (목,금,토)',
  //   thumbnail: '/events/camp/camp-하예서부-main1.png',
  //   price: '790,000원',
  //   intro: {
  //     time: '매주 (월,화,수) / (목,금,토) 수업',
  //     bus: '서울 2호선, 6호선 합정역 셔틀 출발',
  //     option: '2인 1실/ 4인 1실 숙소 및 점심 포함',
  //     group: '2종 보통 그룹 수업',
  //   },
  //   productInfo: {
  //     title: '[서울 출발] 2박 3일 면허 취득 캠프(2종 보통)',
  //     date: '(월,화,수) / (목,금,토)',
  //     age: '만 19세 이상',
  //     person: '6인',
  //     type: '2종 보통만 가능',
  //     meetingTime: '오전 7시 30분',
  //     meetingPlace: '합정역 8번 출구 앞, 마포한강푸르지오 1차 주차장',
  //   },
  //   courseInfo: 3,
  //   useInfo: {
  //     include: [
  //       '하예서부 자동차운전전문학원 수강료 및 도로 주행 시험, 장내 기능 시험 1회 비용',
  //       '보험비, 인지대비',
  //       '파주 게스트 하우스 1인실 2박3일',
  //       '아침 식사, 점심 식사',
  //       '셔틀',
  //     ],
  //   },
  //   campAcademyInfo: {
  //     academyBackground: [
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-camp-1.jpg?alt=media&token=521b4977-eac4-43f9-ac73-7e91b528e81a',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-camp-2.jpg?alt=media&token=44c7af21-f6d9-42c0-b2fb-cc1693ed0684',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-camp-3.jpg?alt=media&token=975bd2bd-20fe-4ca8-81e2-a7dccc1dcc72',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-camp-4.jpg?alt=media&token=1f2b36de-2c95-4935-8444-6b93a438f43f',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-camp-5.jpg?alt=media&token=77ca5c92-63a1-4ab3-8da9-be52c83affa3',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-camp-6.jpg?alt=media&token=725c4040-202a-4c76-afae-3821dfbc7b1c',
  //     ],
  //     academyOption: [
  //       '식당 구비 및 점심 식사 제공',
  //       '쉬운 도로주행코스, 좋은 퀄리티의 수업',
  //       '높은 합격률',
  //     ],
  //     dormBackground: [
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-dorm-1.jpg?alt=media&token=4d03d94c-2476-465a-a557-2aece72c8b76',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-dorm-2.jpg?alt=media&token=eb3559cc-00f3-40d7-aab7-d6a47177d402',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-dorm-3.jpg?alt=media&token=46a67f66-4e79-469d-8a3b-c6a7c4df570b',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-dorm-4.jpg?alt=media&token=172547ec-7d10-452a-9b09-91a6a61e0b10',
  //       'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhaye%2Fhaye-dorm-5.jpg?alt=media&token=9a281313-2a20-4ccc-893a-bf6b0e123b7c',
  //     ],
  //     dormOption: ['1층 공용공간 제공', '깔끔하고 깨끗한 시설', '간단한 조식 제공'],
  //   },
  // },

  {
    id: 'ZJawK6iLTTE4mYm4ndy9',
    academyName: '원영',
    academyLocation: '제주 서귀포시 남원읍 태위로398번길 31',
    dormName: '도토리 게스트하우스',
    dormLocation: '제주특별자치도 서귀포시 남원읍 신흥고수로 9',
    title: '[제주 서귀포] 제주에서 힐링까지! 3박 4일 2종 보통 면허 취득 캠프',
    subTitle: '(원영 자동차운전전문학원 / 2종 보통)',
    description: '(화,수,목,금)',
    thumbnail: '/events/camp/camp-원영-main1.png',
    price: '879,800원',
    intro: {
      title: '[제주에서 힐링까지] 3박 4일 2종 보통 면허 취득 캠프',
      time: '매주 (화,수,목,금) 수업',
      option: '운전학원 및 숙소 포함 (점심 현장결제)',
      group: '2종 보통 그룹 수업',
    },
    productInfo: {
      title: '[제주 서귀포] 3박 4일 면허 취득 캠프 (2종 보통)',
      date: '(화,수,목,금)',
      age: '만 19세 이상',
      person: '3인',
      type: '2종 보통만 가능',
    },
    courseInfo: 4,
    useInfo: {
      include: ['도토리 게스트하우스 1인실 3박 4일', '아침 식사', '학원에서 숙소까지 셔틀 운행'],
      exclude: ['집에서 숙소까지 셔틀', '보험비, 인지대비 (12,500원 현금 결제)'],
    },
    campAcademyInfo: {
      academyBackground: [
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-camp-1.png?alt=media&token=a8c48146-58ab-4537-a8fd-dcd92569ca5f',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-camp-2.png?alt=media&token=80fe6e0a-0e9b-4163-abb0-82d502ca1399',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-camp-3.png?alt=media&token=a1ef46a9-0551-4856-a68e-0eb6f4ce84b3',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-camp-4.png?alt=media&token=48350556-5a69-498c-aa87-f2cca4eb3ea3',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-camp-5.png?alt=media&token=71b46849-6c55-4267-ab80-5d7b141ee36e',
      ],
      academyOption: [
        '식당 구비 및 점심 식사 제공(현장 결제 5천원)',
        '쉬운 도로 주행 코스, 좋은 퀄리티의 수업',
        '높은 합격률',
      ],
      dormBackground: [
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-dorm-1.png?alt=media&token=85b5ffe8-3781-4bf2-9a23-450b296e4f17',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-dorm-2.png?alt=media&token=6f3f03e1-61a1-4db5-b044-f35f293de0e4',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-dorm-3.png?alt=media&token=dcb17af2-e540-4644-8a75-aa312c607ba6',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-dorm-4.png?alt=media&token=deea3ad5-598e-4a7b-a05d-d105eed534ed',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-dorm-5.png?alt=media&token=d96a9ce3-f85a-4151-a9e1-a078d52e4530',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fwonyoung%2Fwon-dorm-6.png?alt=media&token=f456ab14-4a42-4c10-b016-0715b3a57bc0',
      ],
      dormOption: [
        '각 객실별 내부 화장실, 샤워실 보유',
        '세스코 멤버스 가입으로 철저한 위생 관리',
        '조식 제공',
      ],
    },
  },
  {
    id: 'D2sqPHdqrmuerQurjyWx',
    academyName: '현대',
    academyLocation: '제주 제주시 애월읍 소길리 1363-18',
    dormName: '동행in협재',
    dormLocation: '제주특별자치도 제주시 한림읍 협재1길 27',
    // title: `[제주에서 힐링까지] 3박 4일 2종 보통 면허 취득 캠프`,
    title: '[제주 협재] 제주에서 힐링까지! 3박 4일 2종 보통 면허 취득 캠프',
    subTitle: '(현대 자동차운전전문학원 / 2종 보통)',
    description: '(일,월,화,수) · (화,수,목,금)',
    thumbnail: '/events/camp/camp-현대-main1.png',
    price: '843,500원',
    intro: {
      title: '[제주에서 힐링까지] 3박 4일 2종 보통 면허 취득 캠프',
      time: '매주 (일,월,화,수) / (화,수,목,금) 수업',
      option: '운전학원 및 숙소 포함',
      group: '2종 보통 그룹 수업',
    },
    productInfo: {
      title: '[제주 협재] 3박 4일 면허 취득 캠프 (2종 보통)',
      date: '(일,월,화,수) / (화,수,목,금)',
      age: '만 19세 이상',
      person: '3인',
      type: '2종 보통만 가능',
    },
    courseInfo: 4,
    useInfo: {
      include: [
        '동송 자동차운전전문학원 수강료 및 도로 주행 시험, 장내 기능 시험 1회 비용',
        '동행in협재 도미토리 3박4일',
        '점심식사(학원 제공), 저녁식사(숙소 제공)',
        '학원에서 숙소까지 셔틀 운행',
      ],
      exclude: ['제주 항공편', '보험비, 인지대비(학원 결제)'],
    },
    campAcademyInfo: {
      academyBackground: [
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-camp-1.png?alt=media&token=4f67a080-ad0e-4f03-a3d6-29daca6b065b',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-camp-2.png?alt=media&token=e10fe0b9-5f85-4c05-a88b-a6784dcad064',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-camp-3.png?alt=media&token=a710e992-e90e-4dee-a581-2e1b899ea6d0',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-camp-4.png?alt=media&token=8f6d8d63-095a-4e65-aef4-b374bbce8668',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-camp-5.png?alt=media&token=b2f06d3a-3e17-4efb-840c-593bb3f5e919',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-camp-6.png?alt=media&token=a8e4dd08-1173-4eee-a06e-f49b76f0a482',
      ],
      academyOption: [
        '식당 구비 및 점심 식사 제공',
        '쉬운 도로 주행 코스, 좋은 퀄리티의 수업',
        '높은 합격률',
      ],
      dormBackground: [
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-dorm-1.png?alt=media&token=3345050b-4170-4388-9ed1-e2623b6f1047',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-dorm-2.png?alt=media&token=aec47168-f563-4ece-8e6b-856380ccfbea',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-dorm-3.png?alt=media&token=a7f5dc3c-9099-4115-bce1-96da2282bcbb',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-dorm-4.png?alt=media&token=c5d8c175-b337-460b-941d-0e79aa41bdeb',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-dorm-5.png?alt=media&token=46b7b74a-cc00-4188-a90f-89c85ce04607',
        'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/dt-camp%2Fhyundai%2Fhundai-dorm-6.png?alt=media&token=0d29890e-9286-4b9d-b068-f7ca6e5b7154',
      ],
      dormOption: [
        '2인실/4인실',
        '객실마다 개별 화장실 구비',
        '미니벨로 자전거 무료대여',
        '저녁 식사(파티)',
      ],
    },
  },
];
