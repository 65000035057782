import { useEffect } from 'react';

import { LogEvent } from '@analytics/LogEvent/LogEvent';
import { isEmpty } from 'lodash-es';

import { usePageQuery } from '@hooks/use-handle-url';

import { useApplicationFunnel } from './use-application-funnel';

export const useApplicationAnalytics = () => {
  const { currentScreen, academy, lessonInfo } = useApplicationFunnel();
  const { applicationMode, isVerified, isReady } = usePageQuery<'/academy/[id]/application'>();

  useEffect(() => {
    if (!isReady) return;

    LogEvent.매출.purchaseButtonReturn('fail');
    if (isVerified) {
      LogEvent.매출.verificationReturn('success');
    } else {
      LogEvent.매출.verificationReturn('fail');
    }
  }, [isReady]);

  useEffect(() => {
    if (isEmpty(applicationMode) || !academy) return;

    if (currentScreen === 'RegistTypeSelect') {
      LogEvent.매출.$배송지추가(academy, applicationMode);
    } else if (currentScreen === 'LessonsSelect') {
      LogEvent.매출.$위시리스트추가(academy, applicationMode);
    }

    const lesson =
      lessonInfo &&
      academy.selectLesson({
        code: lessonInfo.lessonCode,
        periodText: lessonInfo.lessonPeriodText,
        registType: lessonInfo.lessonRegistType as RegistType,
      });

    // 이 이후로는 Lesson이 있어야 함.
    if (!lesson) return;

    if (currentScreen === 'DateSelect') {
      LogEvent.매출.$장바구니에추가(academy, lesson, applicationMode);
    } else if (currentScreen === 'Purchase') {
      LogEvent.매출.$결제수단등록(academy, lesson, applicationMode);
    }
  }, [currentScreen]);
};
