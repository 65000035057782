import { pipe } from 'shared-values';

import { Block, ClickAction, HightlightBlockAction, Section } from '../LessonsSelect.store';

/**
 * block을 클릭했을 때, block을 highlight하는 action을 추가함.
 */
const highlightBlockAction = (
  actions: ClickAction[],
  payload: HightlightBlockAction['payload'],
) => {
  return [
    ...actions,
    {
      action: 'hightlight-block',
      payload,
    } as ClickAction,
  ];
};

/**
 * section들이 본인의 section을 기억하도록 해서, 클릭했을 때 본인의 section을 복사하도록 함.
 * cloneSection은 addSection과 dependency가 있어서 가장 먼저 들어가야 함.
 */
const cloneSectionAction = (actions: ClickAction[], currentSection: Section) => {
  return [
    {
      action: 'clone-section',
      payload: { section: currentSection },
    } as ClickAction,
    ...actions,
  ];
};

export const attatchDefaultActions = (
  actions: ClickAction[],
  currentSection: Section,
  currentBlock: Block,
) =>
  pipe<ClickAction>(
    (actions) =>
      highlightBlockAction(actions, {
        section: currentSection,
        block: currentBlock,
      }),
    (actions) => cloneSectionAction(actions, currentSection),
  )(actions);
