import { formatDate } from 'shared-values';

export const getHighlightDates = (selectedDate: Date | null, ExcludeDates?: Date[]) => {
  if (!selectedDate) return [];
  const isSelectedDateIncludesInExcludesDate =
    ExcludeDates &&
    ExcludeDates.filter(
      (date) => formatDate(date, 'YYYY-MM-DD') === formatDate(selectedDate, 'YYYY-MM-DD'),
    );

  if (isSelectedDateIncludesInExcludesDate && isSelectedDateIncludesInExcludesDate.length)
    return [];
  return [selectedDate];
};
