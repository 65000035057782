import { useGetUser } from 'database';
import { BottomSheet, NewBoxButton, useToast } from 'design-system';
import { useRouter } from 'next/router';

import { CouponCard } from '@components';
import { NeedLoginBottomSheet } from '@components/NeedLoginBottomSheet/NeedLoginBottomSheet';

import { ERROR_MESSAGE } from '@constants';

interface CouponBottomSheetProps {
  isOpen: boolean;
  close: () => void;
  downloadable?: boolean;
  coupons: CouponCode[];
  onClickReceiveCoupon?: (coupon: CouponCode | IssuedCoupon) => void;
  onClickReceiveAllCoupons?: (coupons?: CouponCode[]) => void;
}

const addAllCoupons = async (user: User, coupons: CouponCode[]) => {
  await Promise.all(
    coupons.map(async (coupon) => {
      if (user.isAlreadyParticipatedCouponEvent(coupon.source)) {
        return null;
      }

      await user.addCoupon({ couponCode: coupon.get() });
    }),
  );
};

export const CouponBottomSheet = (props: CouponBottomSheetProps) => {
  const {
    isOpen,
    close,
    downloadable = true,
    coupons = [],
    onClickReceiveCoupon,
    onClickReceiveAllCoupons,
  } = props;

  const router = useRouter();

  const { user } = useGetUser();

  const isAllAlreadyParticipatedCouponEvent = coupons.every(
    (coupon) => user && user.isAlreadyParticipatedCouponEvent(coupon.source),
  );

  const { openToast } = useToast();

  const redirectToLogin = () => {
    router.push(`/login?redirectUrl=${router.asPath}`);
    close();
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
      header={{ title: '받을 수 있는 쿠폰' }}
      main={
        <div className="max-h-[500px] pb-10">
          <div className="scrollbar-hide flex max-h-[230px] flex-col gap-8 overflow-scroll">
            {coupons.map((coupon) => {
              return (
                <CouponCard
                  coupon={coupon}
                  key={coupon.source}
                  onClickDownloadCoupon={onClickReceiveCoupon}
                  isDisabled={user?.isAlreadyParticipatedCouponEvent(coupon.source)}
                  downloadable={downloadable}
                  onFail={redirectToLogin}
                />
              );
            })}
          </div>
          <NeedLoginBottomSheet>
            <NewBoxButton
              label={isAllAlreadyParticipatedCouponEvent ? '모든 쿠폰 받기 완료' : '모든 쿠폰 받기'}
              fill
              disabled={isAllAlreadyParticipatedCouponEvent}
              onClick={async () => {
                if (!user) return;

                try {
                  await addAllCoupons(user, coupons);

                  openToast({ title: '모든 쿠폰이 발급되었습니다.', yPosition: 50 });
                } catch (error) {
                  if (error instanceof Error) {
                    alert(error.message);
                  } else {
                    alert(ERROR_MESSAGE.UNDEFINED);
                  }
                }

                onClickReceiveAllCoupons && onClickReceiveAllCoupons(coupons);
                close();
              }}
            />
          </NeedLoginBottomSheet>
        </div>
      }
    />
  );
};
