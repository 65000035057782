import { Dialog, DialogButton } from 'design-system';

export const OneLargeDialog = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  if (!isOpen) return null;

  return (
    <Dialog
      image={{ src: '/banners/community.png', alt: 'community' }}
      title={'운전 경력이 1년 이상인가요?'}
      body={'1종 대형은 1/2종 보통 면허 취득 후\n1년이 지나야 준비하실 수 있어요.'}
      close={close}
      buttons={[
        <DialogButton
          buttonType='text'
          color='gray-900'
          text='확인했어요'
          key={'type'}
          onClick={close}
        />,
      ]}
      buttonMatrix={'horizontal'}
      className='text-new-DTPurple-500'
    />
  );
};
