import { type FormEvent, useEffect, useState } from 'react';

import { useGetUser } from '@db/collections';
import { gcooEventParticipatedAtom } from '@jotaiStore';
import axios from 'axios';
import { createHash } from 'crypto';
import { NewBoxButton, useToast } from 'design-system';
import { db } from 'firebase-config';
import { doc, updateDoc } from 'firebase/firestore';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';
import {
  BACKEND_URL,
  merchantKey as cardMerchantKey,
  mid as cardMid,
  cutByBytes,
  formatDate,
  generateRandomString,
  keyInMerchantKey,
  keyInMid,
} from 'shared-values';

import { isAppApproaching, isMobileApproaching } from '@utils/Common';

import { addReceipt } from './actions';

const now = new Date();

interface PurchaseButtonProps {
  purchaseCondition: Record<
    string,
    {
      value: boolean;
      message: string;
      ref: HTMLDivElement | null;
    }
  >;
  totalPrice: number;
  discountPrice: number;
  receipt: Omit<ReceiptData, 'id'>;
  visitTrainingReceipt?: VisitTrainingReceiptData;
  addressString: string;
  couponPrice?: number;
  isTogetherEvent: boolean;
  isKeyInPurchase: boolean;
  운전선생할인금: number;
  시뮬레이션할인금: number;
  onClickPurchase: (purchaseMethod: '신용 카드' | '간편 결제') => void;
  discounts: Discount[];
  selectedCoupons: CouponCode[];
}

const isAppApproach = isAppApproaching();

const isMobile = isMobileApproaching() || isAppApproaching();

export const PurchaseButton = ({
  purchaseCondition,
  totalPrice,
  discountPrice,
  receipt,
  visitTrainingReceipt,
  addressString,
  couponPrice,
  isTogetherEvent,
  isKeyInPurchase,
  운전선생할인금,
  시뮬레이션할인금,
  onClickPurchase,
  discounts,
  selectedCoupons,
}: PurchaseButtonProps) => {
  const router = useRouter();
  const { user } = useGetUser();
  const [mid, setMid] = useState(cardMid);
  const [merchantKey, setMerchantKey] = useState(cardMerchantKey);
  const [ediDate, setEdiDate] = useState(formatDate(now, 'YYYYMMDDHHMMSS'));
  const [goodsAmt, setGoodsAmt] = useState(
    totalPrice.toString(),
    // process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production' ? totalPrice.toString() : '100',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [ordNo, setOrdNo] = useState(generateRandomString(20));
  const gcooEventParticipated = useAtomValue(gcooEventParticipatedAtom);

  const canPurchase = Object.values(purchaseCondition).every((condition) => condition.value);

  const couponIds = user
    ? JSON.stringify(
        selectedCoupons
          .filter((coupon) => user.coupons.find((userCoupon) => userCoupon.id === coupon.id))
          .map((coupon) => coupon.id),
      )
    : '[]';

  useEffect(() => {
    setGoodsAmt(totalPrice.toString());
  }, [totalPrice]);

  useEffect(() => {
    if (isKeyInPurchase) {
      setMid(keyInMid);
      setMerchantKey(keyInMerchantKey);
    } else {
      setMid(cardMid);
      setMerchantKey(cardMerchantKey);
    }
  }, [isKeyInPurchase]);

  useEffect(() => {
    //결제창 종료 함수 <<'returnData' 수정 불가능>>
    const returnData = async (e: MessageEvent) => {
      const frm = document.payInit;

      if (!e.data || !e.data.resultCode) {
        return;
      }

      if (e.data.resultCode === '0000') {
        await receive_result(e.data.data, frm.returnUrl.value);
      } else if (e.data.resultCode === 'XXXX') {
        setIsLoading(false);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('message', returnData);
    }
    return () => {
      window.removeEventListener('message', returnData);
    };
  }, []);

  useEffect(() => {
    const payScreen = document.getElementById('pay_screen');

    if (!payScreen) return;

    if (isLoading) {
      payScreen.style.display = 'block';
    } else {
      payScreen.style.display = 'none';
    }

    return () => {
      payScreen.style.display = 'none';
    };
  }, [isLoading]);

  const { openToast } = useToast();

  if (!user) return null;

  const saveAddress = async () => {
    if (!user.uid) return;
    const { userAddress = '', userDetailAddress = '' } = receipt;

    const userRef = doc(db, 'User', user.uid);
    await updateDoc(userRef, {
      address: userAddress,
      detailAddress: userDetailAddress,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (!canPurchase || isLoading) return;
    if (!receipt.selectedRange && !receipt.selectedTimes.length) {
      router.back();
      openToast({ title: '알 수 없는 오류가 발생했어요. 날짜 선택을 다시 해주세요.' });
      return;
    }

    // airbridge event
    onClickPurchase(isKeyInPurchase ? '신용 카드' : '간편 결제');
    // backend 서버 켜져 있는지 확인
    setIsLoading(true);

    const itemPrice = Number(goodsAmt);
    await saveAddress();
    // DB에 결제 정보를 저장
    await addReceipt({
      orderId: ordNo.toString(),
      receipt,
      visitTrainingReceipt,
      itemPrice,
      discountPrice,
      isKeyInPurchase,
      originalUrl: location.href,
      gcooEvent: gcooEventParticipated,
      운전선생할인금,
      시뮬레이션할인금,
      discounts,
      firstComeCouponCode: selectedCoupons.find((c) => c.source === 'first-come')?.code ?? '',
    });
  };

  const receive_result = async (
    data: { amt: string; ediDate: string; tid: string },
    url: string,
  ) => {
    setIsLoading(true);
    try {
      const { amt, ediDate, tid } = data;

      const result = await axios({
        url,
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: {
          tid: tid,
          goodsAmt: amt,
          ediDate: ediDate,
          orderId: ordNo,
        },
      });

      if (result.data.status === 'success') {
        await router.replace(`/application/complete?merchant_uid=${ordNo}`);
        return;
      }

      await router.replace(
        `/application/fail?message=${encodeURIComponent(
          result.data.message,
        )}&original_url=${encodeURIComponent(result.data.original_url)}`,
      );

      return;
    } catch (error) {
      alert('결제하는데 문제가 발생했습니다. 운전선생 카카오톡 채널로 문의해주세요.');
    }
    setIsLoading(false);
  };

  const renderButton = () => {
    if (!canPurchase) {
      return (
        <button
          type="button"
          className="w-full"
          onClick={() => {
            const findCondition = Object.values(purchaseCondition).find(
              (condition) => !condition.value,
            );

            if (!findCondition) return;

            findCondition.ref?.scrollIntoView({ behavior: 'smooth' });
            openToast({
              title: findCondition.message,
            });
          }}
        >
          <NewBoxButton
            label="결제하기"
            state="inactive"
            onClick={(e) => e.preventDefault()}
            className="!w-[171px] shrink-0"
          />
        </button>
      );
    }

    if (isMobile) {
      return (
        <NewBoxButton
          id="payBtn"
          label="결제하기"
          styles="filled-yellow"
          className="!w-[171px] shrink-0"
          onClick={async (e) => {
            e.preventDefault();
            if (!canPurchase || isLoading) return;

            /**
             * 이전 단계에서 날짜 선택이 안되었을 경우 예외 처리
             */
            if (!receipt.selectedRange && !receipt.selectedTimes.length) {
              router.back();
              openToast({ title: '알 수 없는 오류가 발생했어요. 날짜 선택을 다시 해주세요.' });
            }

            sessionStorage.setItem('purchase-redirect-ga', 'true');
            sessionStorage.setItem('purchase-redirect-db', 'true');
            onClickPurchase(isKeyInPurchase ? '신용 카드' : '간편 결제');
            // airbridge event

            // backend 서버 켜져 있는지 확인
            const { status } = await axios.get(BACKEND_URL);

            if (status !== 200) {
              alert('결제하는데 오류가 발생했습니다. 잠시 후에 다시 시도해주세요,');
              setIsLoading(false);
              router.replace('/');
              return;
            }

            if (isAppApproach) {
              window.flutter_inappwebview.callHandler('IsPurchasing', 'true');
            }

            await saveAddress();

            await addReceipt({
              orderId: ordNo.toString(),
              receipt,
              visitTrainingReceipt,
              itemPrice: Number(goodsAmt),
              discountPrice,
              isKeyInPurchase,
              originalUrl:
                location.href.slice(-2) === '#;' ? location.href.slice(0, -2) : location.href,
              gcooEvent: gcooEventParticipated,
              운전선생할인금,
              시뮬레이션할인금,
              discounts,
              firstComeCouponCode:
                selectedCoupons.find((c) => c.source === 'first-come')?.code ?? '',
            });
            const isStage = Boolean(
              process.env.NEXT_PUBLIC_PLATFORM_ENV &&
                process.env.NEXT_PUBLIC_PLATFORM_ENV !== 'production',
            );
            document.payInit.returnUrl.value = `${BACKEND_URL}/purchase/complete-simulation/mobile?isStage=${isStage}&couponIds=${couponIds}&userId=${user.uid}&isTogetherEvent=${isTogetherEvent}`;
            document.payInit.action = 'https://api.kispg.co.kr' + '/v2/auth';
            document.payInit.submit();
          }}
        />
      );
    }

    return (
      <NewBoxButton
        label="결제하기"
        type="submit"
        styles="filled-yellow"
        disabled={!canPurchase}
        className="!w-[171px] shrink-0"
      />
    );
  };

  const popUpOptions = {
    action: 'https://api.kispg.co.kr/v2/auth',
    onSubmit: handleSubmit,
    target: 'pay_frame',
  };

  return (
    <>
      <div>
        <form name="payInit" method="post" {...(!isMobile ? popUpOptions : {})}>
          <input type="hidden" name="payMethod" value="card" />
          <input type="hidden" name="trxCd" value="0" />
          <input type="hidden" name="mid" value={mid} />
          <input
            type="hidden"
            name="goodsNm"
            value={`${receipt.academyName} (${receipt.lessonName})`}
          />
          <input type="hidden" name="ordNo" value={ordNo} />
          <input type="hidden" name="goodsAmt" value={goodsAmt} />
          <input
            type="hidden"
            name="ordNm"
            value={
              !isEmpty(receipt.visitorName)
                ? receipt.visitorName
                : !isEmpty(user.realName)
                ? user.realName
                : user.name
            }
          />
          <input
            type="hidden"
            name="ordTel"
            value={!isEmpty(receipt.visitorPhoneNum) ? receipt.visitorPhoneNum : user.phoneNum}
          />
          <input type="hidden" name="ordEmail" value={user.email} />
          <input
            type="hidden"
            name="returnUrl"
            value={`${BACKEND_URL}/purchase/complete-simulation?couponIds=${couponIds}&userId=${user.uid}&isTogetherEvent=${isTogetherEvent}`}
          />
          <input type="hidden" name="userIp" value="0:0:0:0:0:0:0:1" />
          <input type="hidden" name="mbsUsrId" value={cutByBytes(user.uid, 20)} />
          <input type="hidden" name="ordGuardEmail" value="" />
          <input type="hidden" name="rcvrAddr" value={cutByBytes(addressString, 100)} />
          <input type="hidden" name="rcvrPost" value="00100" />
          <input type="hidden" name="mbsIp" value="127.0.0.1" />
          <input type="hidden" name="mbsReserved" value={''} />
          <input type="hidden" name="rcvrMsg" value="rcvrMsg" />
          <input type="hidden" name="goodsSplAmt" value="0" />
          <input type="hidden" name="goodsVat" value="0" />
          <input type="hidden" name="goodsSvsAmt" value="0" />
          <input type="hidden" name="payReqType" value="1" />
          <input type="hidden" name="model" value="WEB" />
          <input type="hidden" name="charSet" value="UTF-8" />
          <input type="hidden" name="ediDate" value={ediDate} />
          <input
            type="hidden"
            name="encData"
            value={createHash('sha256')
              .update(mid + ediDate + goodsAmt + merchantKey)
              .digest('hex')}
          />
          {renderButton()}
        </form>
      </div>
    </>
  );
};
