import { SimulationLesson } from '@db/collections';
import { LICENSE_CONTENT, findMultiLessonsOnLicense } from 'shared-values';

import { Block } from '../../../LessonsSelect.store';

const getLessonSortOrder = (variant: string): number => {
  if (variant === '신규') return 1;
  if (variant === '도로주행') return 2;
  if (variant === '경력자') return 3;
  return 0;
};

const convertVaraint = (variant: string): string => {
  if (variant === '신규') return '처음부터 수강';
  if (variant === '도로주행') return '도로주행만 수강';
  if (variant === '경력자') return '면허 재취득';

  return variant;
};

/**
 * {면허} : {수업}[] 의 형식으로 묶어주는 함수
 * ex : {TWO_NORMAL} : [2종소형(신규), 2종소형(1,2종 보유자)]
 */
const groupLessonsByLicenseCode = (lessons: Lesson[]): Record<LicenseCode, Lesson[]> =>
  lessons.reduce(
    (acc: Record<LicenseCode, Lesson[]>, lesson) => ({
      ...acc,
      [lesson.licenseCode]: [...(acc[lesson.licenseCode] || []), lesson],
    }),
    {} as Record<LicenseCode, Lesson[]>,
  );

const createAddSectionBlock = (
  lessons: Lesson[],
  licenseCode: LicenseCode,
  isSimulation: boolean,
): Block => {
  return {
    label: LICENSE_CONTENT[licenseCode].title,
    subLabel: '',
    isPopular: lessons.some((lesson) => lesson.isPopular),
    isNew: lessons.some((lesson) => lesson.isNew),
    clickActions: [
      {
        action: 'add-section',
        payload: {
          section: {
            title: isSimulation
              ? '기간/시간'
              : licenseCode === 'TWO_SMALL'
              ? '면허 보유 여부'
              : '수강 단계',
            clickedBlockLabel: '',
            blocks: lessons
              .toSorted(
                (a, b) => getLessonSortOrder(a.variant ?? '') - getLessonSortOrder(b.variant ?? ''),
              )
              .map((lesson) => {
                return {
                  ...createAddDataBlock(lesson),
                  label:
                    lesson.licenseCode === 'TWO_SMALL' && lesson.variant
                      ? lesson.variant
                      : lesson.variant && !isSimulation
                      ? convertVaraint(lesson.variant)
                      : lesson.label,
                  // 하위 블록에서 isPopular, isNew는 끄기
                  isPopular: false,
                  isNew: false,
                };
              }),
          },
        },
      },
      {
        action: 'reset-lesson-info',
      },
    ],
  };
};

const createAddDataBlock = (lesson: Lesson | SimulationLesson): Block => {
  const label = lesson instanceof SimulationLesson ? lesson.periodText : lesson.label;
  return {
    label,
    subLabel:
      lesson instanceof SimulationLesson
        ? lesson.renderPrice
        : lesson.code.includes('TRAINING')
        ? lesson.renderPrice
        : '',
    isPopular: Boolean(lesson.isPopular),
    isNew: Boolean(lesson.isNew),
    clickActions: [
      {
        action: 'add-data',
        payload: { lessonInfo: lesson.lessonInfo },
      },
    ],
  };
};

const generateBlocks = (
  lessons: Lesson[],
  licenseCode: LicenseCode,
  isSimulation: boolean,
): Block[] => {
  // 운전학원이면서 연수를 선택한 경우 fake Button을 생성
  if (!isSimulation && licenseCode === 'TRAINING') {
    const fakeTrainingBlocks = (['TWO_AUTO', 'ONE_AUTO'] as LicenseCode[]).map((licenseCode) =>
      createAddSectionBlock(lessons, licenseCode, false),
    );

    return fakeTrainingBlocks;
  } else if (
    // 시뮬레이션의 경우, 무조건 AddSection Block을 생성
    isSimulation ||
    // 운전학원의 경우, group으로 묶이는 경우에 대해서 AddSection Block을 생성
    findMultiLessonsOnLicense(licenseCode)
  ) {
    return [createAddSectionBlock(lessons, licenseCode, isSimulation)];
  }
  // 그 외의 경우, AddData Block을 생성
  else {
    return [createAddDataBlock(lessons[0])];
  }
};

export const generateBlocksWithLessons = (lessons: Lesson[], isSimulation: boolean): Block[] => {
  const groupedLessons = groupLessonsByLicenseCode(lessons);
  return Object.entries(groupedLessons).flatMap(([licenseCode, groupedLessons]) =>
    generateBlocks(groupedLessons, licenseCode as LicenseCode, isSimulation),
  );
};
