import { Spacer } from 'design-system';

export const Section = ({
  title,
  subTitle,
  children,
}: {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <h4 className="text-new-Body1-bold">{title}</h4>
      <Spacer className="h-16" />
      {subTitle && (
        <>
          <Spacer className="h-8" />
          <p className="text-new-Body1-medium text-new-gray-500">{subTitle}</p>
        </>
      )}
      <div className="grid grid-cols-2 gap-8">{children}</div>
      <Spacer className="h-32" />
    </div>
  );
};
