import { Spacer } from 'design-system';

interface PaymentProps {
  isKeyInPurchase: boolean;
  setIsKeyInPurchase: (isKeyInPurchase: boolean) => void;
}

export const Payment = ({ isKeyInPurchase, setIsKeyInPurchase }: PaymentProps) => {
  return (
    <>
      <Spacer className='h-32' />
      <div>
        <h4 className='text-new-Sub-Title text-new-gray-900'>결제 수단</h4>
        <Spacer className='h-20' />
        <div className='flex items-center gap-6' onClick={() => setIsKeyInPurchase(true)}>
          <input
            type='radio'
            readOnly
            checked={isKeyInPurchase}
            className='checked:border-new-DTYellow-400 h-24 w-24 border-[1.6px] border-gray-200 bg-white checked:border-[6px]'
          />
          <label className='text-new-Body1-medium'>신용 카드</label>
        </div>
        <div className='mt-16 flex items-center gap-6' onClick={() => setIsKeyInPurchase(false)}>
          <input
            type='radio'
            readOnly
            checked={!isKeyInPurchase}
            className='checked:border-new-DTYellow-400 border-new-gray-200 h-24 w-24 border-[1.6px] bg-white checked:border-[6px]'
          />
          <label className='text-new-Body1-medium'>카카오페이 / 네이버페이 / 페이코 / 앱카드</label>
        </div>
      </div>
      <Spacer className='h-32' />
    </>
  );
};
