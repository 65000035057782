import { useEffect, useState } from 'react';

import { NewIcon, Spacer } from 'design-system';
import Image from 'next/image';

import { useWindowSize } from '@hooks';

const getNextOpacity = (prev: number) => {
  if (prev === 1) return 0.25;
  if (prev === 0.25) return 0.5;
  if (prev === 0.5) return 1;
  return 1;
};

export const Boarding = () => {
  const { height } = useWindowSize();

  const [opacity, setOpacity] = useState(0.25);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity((prev) => getNextOpacity(prev));
    }, 700);
    return () => clearInterval(interval);
  }, []);


  return (
    <div style={{ height: height }} className='bg-white pb-[210px] pt-[172px]'>
      <div className='flex flex-col items-center justify-center'>
        <span className='text-new-Section-Title'>운전학원 온라인 예약 플랫폼</span>
        <Spacer className='h-12' />
        <span className='text-new-Title2 text-center'>
          운전선생으로 <br />
          연결 중입니다.
        </span>
        <Spacer className='h-47' />
        <div className='gap-17 flex'>
          <NewIcon icon='academy-cta-64' />
          <div className='flex items-center justify-center'>
            <NewIcon icon='right-direction' opacity={opacity} />
            <NewIcon icon='right-direction' opacity={getNextOpacity(opacity)} />
            <NewIcon icon='right-direction' opacity={getNextOpacity(getNextOpacity(opacity))} />
          </div>
          <Image src='/phone-check.png' alt='운전선생' width={42} height={74} />
        </div>
        <Spacer className='h-[60px]' />
        <span className='text-new-Body1-medium text-new-gray-500 text-center'>
          학원에 방문할 필요 없이 <br />
          예약을 완료하세요!
        </span>
        <Spacer className='h-[78px]' />
        <Image src='/dt-new-logo.jpg' alt='운전선생' width={84} height={22} />
      </div>
    </div>
  );
};
