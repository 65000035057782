import { ComponentProps, ReactNode } from 'react';

import { Pressed } from '@ds/components/reusable';
import { Label, NewBoxButton } from 'design-system';
import { cn } from 'tailwind-config';

interface SubLabelButtonProps extends ComponentProps<typeof NewBoxButton> {
  subLabel: string;
  isNew: boolean;
  isPopular: boolean;
}

const LabelAttatchedBlock = ({
  isPopular,
  isNew,
  children,
}: {
  isPopular: boolean;
  isNew: boolean;
  children: ReactNode;
}) => {
  return (
    <div className="relative">
      {isNew ? (
        <Label styles="filled-purple" label={'신규'} className="absolute -top-6 left-10 z-10" />
      ) : (
        isPopular && (
          <Label styles="filled-red" label={'추천'} className="absolute -top-6 left-10 z-10" />
        )
      )}
      {children}
    </div>
  );
};

/**
 * SubLabelButton은 DS에 포함되지 않아서 따로 정의한 컴포넌트입니다.
 */
const SubLabelButton = ({
  label,
  subLabel,
  state,
  className,
  ...props
}: Omit<SubLabelButtonProps, 'isPopular'>) => {
  return (
    <button
      className={cn(
        'text-new-Body2-bold inactive:pointer-events-none border-new-gray-200 text-new-gray-900 inactive:text-new-gray-500 selected:bg-[#FFF6131A] selected:border-new-DTYellow-400 relative w-full min-w-[100px] items-center justify-center gap-4 whitespace-nowrap rounded-[10px] border-[1.8px] px-16 py-10',
        className,
        state,
      )}
      {...props}
    >
      <p>{label}</p>
      <p className="text-new-Caption1-medium text-new-gray-500">{subLabel}</p>
      <Pressed />
    </button>
  );
};

export const Block = ({ subLabel, ...props }: SubLabelButtonProps) => {
  return (
    <LabelAttatchedBlock {...props}>
      {subLabel.length > 0 ? (
        <SubLabelButton subLabel={subLabel} {...props} />
      ) : (
        <NewBoxButton styles="outlined" size="medium" fill={true} {...props} />
      )}
    </LabelAttatchedBlock>
  );
};
