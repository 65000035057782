import { StateCreator } from 'zustand';

import { ApplicationData } from '@templates/Application/hooks';

export interface PurchaseStore {
  discounts: Discount[];
  setDiscounts: (discounts: Discount[]) => void;
  discountResult: DiscountReturn;
  setDiscountResult: (discountResult: DiscountReturn) => void;
  selectedCoupons: CouponCode[];
  setSelectedCoupons: (coupons: CouponCode[]) => void;
}

/**
 * discounts와 discountResult는 하나로 통합될 수 있는 정보이지만, backend에 정보를 보낼 때 두 정보 모두 필요하여
 * 별도로 선언하였습니다. 추후 백엔드 로직 정리 후 하나로 통합할 예정입니다.
 */
export const purchaseStore: StateCreator<ApplicationData, [], [], PurchaseStore> = (set) => ({
  discounts: [],
  setDiscounts: (discounts: Discount[]) => set({ discounts }),
  discountResult: {
    원금: 0,
    할인금: 0,
    운전선생할인금: 0,
    시뮬레이션할인금: 0,
    최종금: 0,
  },
  setDiscountResult: (discountResult: DiscountReturn) => set({ discountResult }),
  selectedCoupons: [],
  setSelectedCoupons: (coupons: CouponCode[]) => set({ selectedCoupons: coupons }),
});
