type DayOfWeek = '일' | '월' | '화' | '수' | '목' | '금' | '토';
type ScheduleInput = [DayOfWeek, number];

interface GenerateScheduleParams {
  daysOffset: number;
  days: ScheduleInput[];
}

interface ScheduleObject {
  startDate: string;
  endDate: string;
  text: string;
}

const LIMIT = 4;

function generateSchedule({ daysOffset, days }: GenerateScheduleParams): ScheduleObject[] {
  const now = new Date();
  const koreaTimezoneOffset = 9 * 60; // 한국은 UTC+9
  const todayInKorea = new Date(now.getTime() + koreaTimezoneOffset * 60000);
  todayInKorea.setHours(0, 0, 0, 0);

  const startDate = new Date(todayInKorea);
  startDate.setDate(startDate.getDate() + daysOffset);

  const dayMap: { [key in DayOfWeek]: number } = {
    일: 0,
    월: 1,
    화: 2,
    수: 3,
    목: 4,
    금: 5,
    토: 6,
  };

  const schedules: ScheduleObject[] = [];

  days.forEach(([startDay, duration]) => {
    let currentDate = new Date(startDate);

    // 지정된 시작 요일로 이동
    while (currentDate.getDay() !== dayMap[startDay]) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    for (let i = 0; i < 4; i++) {
      const scheduleStartDate = new Date(currentDate);
      const scheduleEndDate = new Date(currentDate);
      scheduleEndDate.setDate(scheduleEndDate.getDate() + duration - 1);

      schedules.push(createScheduleObject(scheduleStartDate, scheduleEndDate));

      // 다음 주기의 시작일로 이동
      currentDate.setDate(currentDate.getDate() + 7);
    }
  });

  // 날짜순으로 정렬
  schedules.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  return schedules.slice(0, LIMIT);
}

function createScheduleObject(startDate: Date, endDate: Date): ScheduleObject {
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatShortDate = (date: Date): string => {
    const year = date.getFullYear().toString().slice(2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  const start = formatDate(startDate);
  const end = formatDate(endDate);
  const shortStart = formatShortDate(startDate);
  const shortEnd = formatShortDate(endDate);

  const dayNames = getDayNames(startDate, endDate);

  return {
    startDate: start,
    endDate: end,
    text: `${shortStart}~${shortEnd.slice(3)} (${dayNames})`,
  };
}

function getDayNames(startDate: Date, endDate: Date): string {
  const dayNames: DayOfWeek[] = ['일', '월', '화', '수', '목', '금', '토'];
  const names: DayOfWeek[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    names.push(dayNames[currentDate.getDay()]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return names.join(',');
}

export const campDateRange = {
  동송: generateSchedule({
    daysOffset: 1,
    days: [
      ['수', 3],
      ['일', 3],
    ],
  }),

  하예서부: generateSchedule({
    daysOffset: 4,
    days: [
      ['목', 3],
      ['월', 3],
    ],
  }),
  설악산: generateSchedule({
    daysOffset: 4,
    days: [['토', 4]],
  }),
  강릉: generateSchedule({
    daysOffset: 4,
    days: [
      ['일', 4],
      ['월', 4],
    ],
  }),
  원영: generateSchedule({
    daysOffset: 7,
    days: [['화', 4]],
  }),

  현대: generateSchedule({
    daysOffset: 7,
    days: [
      ['일', 4],
      ['화', 4],
    ],
  }),
};
