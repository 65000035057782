export const formatBirthday = (input: string): string => {
  // 하이픈과 숫자 외의 모든 문자를 제거합니다.
  let numbers = input.replace(/[^0-9]/g, '');

  // 최대 8자리까지만 허용합니다.
  numbers = numbers.substring(0, 8);

  // 숫자를 형식에 맞게 하이픈을 추가합니다.
  const parts: string[] = [];
  if (numbers.length > 4) {
    parts.push(numbers.substring(0, 4)); // YYYY
    if (numbers.length > 6) {
      parts.push(numbers.substring(4, 6)); // MM
      parts.push(numbers.substring(6, 8)); // DD
    } else {
      parts.push(numbers.substring(4)); // MM or MMDD
    }
  } else {
    parts.push(numbers); // YYYY or YYYYM
  }

  return parts.join('-'); // 하이픈으로 조각들을 연결합니다.
};

export const formatPhoneNumber = (input: string) => {
  // 모든 하이픈을 제거합니다.
  let numbers = input.replace(/-/g, '');

  // 최대 11자리까지만 허용합니다.
  numbers = numbers.substring(0, 11);

  // 숫자를 형식에 맞게 하이픈을 추가합니다.
  const parts = [];
  if (numbers.length > 3) {
    parts.push(numbers.substring(0, 3));
    if (numbers.length > 7) {
      parts.push(numbers.substring(3, 7));
      parts.push(numbers.substring(7, 11));
    } else {
      parts.push(numbers.substring(3));
    }
  } else {
    parts.push(numbers);
  }

  return parts.join('-'); // 하이픈으로 조각들을 연결합니다.
};
