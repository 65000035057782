import { StateCreator } from 'zustand';

import { ApplicationData } from '@templates/Application/hooks';

export interface Section {
  title: string;
  subTitle?: string;
  blocks: Block[];
  clickedBlockLabel: string;
}

export interface Block {
  label: string;
  subLabel: string;
  clickActions: ClickAction[];
  isPopular: boolean;
  isNew: boolean;
}

export type ClickAction =
  | AddDataAction
  | AddSectionAction
  | HightlightBlockAction
  | CloneSectionAction
  | ResetLessonInfoAction
  | PopSectionAction;

interface PopSectionAction {
  action: 'pop-section';
}

export interface AddDataAction {
  action: 'add-data';
  payload: {
    lessonInfo: LessonInfo;
  };
}

interface AddSectionAction {
  action: 'add-section';
  payload: {
    section: Section;
  };
}

export interface HightlightBlockAction {
  action: 'hightlight-block';
  payload: {
    section: Section;
    block: Block;
  };
}

interface CloneSectionAction {
  action: 'clone-section';
  payload: { section: Section };
}

interface ResetLessonInfoAction {
  action: 'reset-lesson-info';
}

export interface LessonsSelectStore {
  sections: Section[];
  lessonInfo: LessonInfo | null;
  setLessonInfo: (lessonInfo: LessonInfo | null) => void;
  dispatch: (action: ClickAction) => void;
}

export function lessonsSelectReducer(
  state: LessonsSelectStore,
  action: ClickAction,
): LessonsSelectStore {
  switch (action.action) {
    case 'hightlight-block': {
      const { section, block } = action.payload;

      const newSections = state.sections.map((s) => {
        if (s.title === section.title) {
          return {
            ...s,
            clickedBlockLabel: block.label,
          };
        }
        return s;
      });

      return {
        ...state,
        sections: newSections,
      };
    }
    case 'clone-section': {
      const { section } = action.payload;
      const findSectionIndex = state.sections.findIndex((s) => s.title === section.title);
      return { ...state, sections: state.sections.slice(0, findSectionIndex + 1) };
    }
    case 'pop-section': {
      // 마지막 섹션을 pop하여 제거
      return { ...state, sections: state.sections.slice(0, -1) };
    }
    case 'add-data': {
      const { lessonInfo } = action.payload;
      return { ...state, lessonInfo };
    }
    case 'add-section': {
      return { ...state, sections: [...state.sections, action.payload.section] };
    }

    case 'reset-lesson-info': {
      return { ...state, lessonInfo: null };
    }
    default:
      return state;
  }
}

// Zustand store와 리듀서 로직 생성
export const lessonsSelectStore: StateCreator<ApplicationData, [], [], LessonsSelectStore> = (
  set,
) => ({
  sections: [],
  clickedBlocks: [],
  lessonInfo: null,
  setLessonInfo: (lessonInfo: LessonInfo | null) => set({ lessonInfo }),
  dispatch: (action: ClickAction) => {
    set((state: ApplicationData) => ({ ...state, ...lessonsSelectReducer(state, action) }));
  },
});
