import React, { useEffect } from 'react';

import { useGetUser } from 'database';
import { useRouter } from 'next/router';
import { cn } from 'tailwind-config';

export const PageBase = ({
  children,
  userRequired = false,
  className,
}: {
  children?: React.ReactNode | React.ReactNode[];
  userRequired?: boolean;
  className?: string;
}) => {
  const { user, isLoading } = useGetUser();
  const router = useRouter();

  useEffect(() => {
    if (isLoading || !userRequired) return;
    if (!user) {
      router.replace(`/login?redirectUrl=${encodeURIComponent(router.asPath)}`);
    }
  }, [isLoading, user]);

  if (userRequired && isLoading) return null;

  if (userRequired && !user) return <div className={className} />;

  return <div className={cn('h-full', className)}>{children}</div>;
};
