import { ReactNode } from 'react';

import { Spacer } from 'design-system';

interface FormStyleProps {
  title: string;
  description?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const FormStyle = ({ title, description, children }: FormStyleProps) => {
  return (
    <>
      <Spacer className="h-24" />
      <h1 className="text-new-Section-Title text-new-gray-900">{title}</h1>
      {description ? (
        typeof description === 'string' ? (
          <>
            <Spacer className="h-8" />
            <h2
              className="text-new-Body2-medium text-new-gray-500"
              dangerouslySetInnerHTML={{
                __html: description.replaceAll('\n', '\\n').replaceAll('\\n', '<br />'),
              }}
            />
          </>
        ) : (
          <>
            <Spacer className="h-8" />
            {description}
          </>
        )
      ) : null}
      <Spacer className="h-32" />
      <>{children}</>
    </>
  );
};
