import { BottomSheet, NewIcon, Spacer, useOverlay } from 'design-system';
import { isEmpty } from 'lodash-es';
import Link from 'next/link';

import { ChannelTalkButton } from '@components';
import { globalPreviousURL, isGlobalLinkFromAcademy } from '@pages/academy/[id]/application';

import { useApplicationFunnel } from '../hooks';

interface CardProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  title: string;
  description: string;
}

const Card = ({ title, description, ...props }: CardProps) => {
  return (
    <article
      {...props}
      className="bg-new-gray-50 flex cursor-pointer items-center justify-between rounded-[16px] px-16 py-20"
    >
      <div>
        <p className="text-new-Body2-bold">{title}</p>
        <Spacer className="h-[4px]" />
        <p className="text-new-Caption1-medium text-new-gray-500">{description}</p>
      </div>
      <NewIcon icon="arrowright-outlined" size={16} className="fill-new-gray-400" />
    </article>
  );
};

export const AcademyLinkFloatingBar = () => {
  const { academy, currentScreen } = useApplicationFunnel();

  const overlay = useOverlay();

  if (
    !isGlobalLinkFromAcademy ||
    !academy ||
    isEmpty(currentScreen) ||
    currentScreen === 'RegistTypeSelect'
  )
    return null;

  const { name } = academy;

  return (
    <button
      className="bg-new-gray-100 flex w-full justify-between px-16 py-10"
      onClick={() => {
        overlay.open(({ isOpen, close }) => (
          <BottomSheet
            isOpen={isOpen}
            onClose={close}
            header={{ title: '예약 전, 궁금한 게 있다면?', subTitle: '운전선생이 도와드릴게요!' }}
            main={
              <>
                <div className="flex flex-col gap-8">
                  <div className="shrink-0">
                    <Link
                      href={`/academy/${academy?.id}`}
                      replace
                      onClick={() => {
                        globalPreviousURL.url = window.location.href;
                      }}
                    >
                      <Card
                        title={'🧐 학원 정보 더 알아보기'}
                        description={'수강생들의 솔직한 후기도 볼 수 있어요.'}
                      />
                    </Link>
                  </div>
                  <div className="shrink-0">
                    <ChannelTalkButton pageName="학원 홈페이지">
                      <Card
                        title={'💬 채팅으로 문의하기'}
                        description={'문의를 남겨주시면, 실시간으로 답변해드려요.'}
                      />
                    </ChannelTalkButton>
                  </div>
                </div>
                <Spacer className="h-[16px]" />
              </>
            }
          />
        ));
      }}
    >
      <p className="text-new-Body2-medium">
        🧐
        <span className="text-new-Body2-bold"> {name} 학원</span>에 대해 궁금한 게 있다면?
      </p>
      <NewIcon icon="arrowright-outlined" size={20} className="fill-new-gray-400" />
    </button>
  );
};
