import { useEffect, useState } from 'react';

import { LogEvent } from '@analytics';
import { SelectedRange } from '@db/base-models';
import { NewBoxButton, Spacer, useToast } from 'design-system';

import { ApplicationBottom } from '@templates/Application/components/ApplicationBottom';
import { useApplicationFunnel } from '@templates/Application/hooks';
import { campDateRange } from '@templates/Event/Camp/constants';

interface CampDatedelectPrdops {
  academyName: keyof typeof campDateRange;
}

export const CampDateSelect = ({ academyName }: CampDatedelectPrdops) => {
  const { openToast } = useToast();
  const [selectCourse, setSelectCourse] = useState<number>();

  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const { setSelectedRange } = useApplicationFunnel<'DateSelect'>();

  const campData = campDateRange[academyName];

  const selectCourseHandler = (course: number) => {
    setSelectCourse(course);
    setStartDate(new Date(campDateRange[academyName][course].startDate));
    setEndDate(new Date(campDateRange[academyName][course].endDate));
  };

  useEffect(() => {
    setSelectedRange(
      new SelectedRange({
        startDate: startDate,
        endDate: endDate,
      } as SelectedRange),
    );
  }, [startDate, endDate]);

  return (
    <>
      <Spacer className="h-40" />
      <p className="text-new-Title3">원하는 날짜를 선택해 주세요.</p>
      <Spacer className="h-32" />
      <div className="flex flex-col gap-8">
        {campData.map((course, index) => {
          const { text } = course;
          return (
            <NewBoxButton
              key={index}
              styles="outlined"
              className="w-full"
              state={selectCourse === index ? 'selected' : 'active'}
              onClick={() => selectCourseHandler(index)}
            >
              {text}
            </NewBoxButton>
          );
        })}
      </div>
      <ApplicationBottom>
        {({ onClick, disabled }) => (
          <div
            onClick={() => {
              if (startDate && endDate) {
                LogEvent.유입.licenseCampAction({ startDate, endDate });
              }
              if (selectCourse === undefined) {
                openToast({ title: '캠프 날짜를 선택해 주세요.' });
              }
            }}
          >
            <NewBoxButton
              label="다음"
              fill
              styles={'filled-yellow'}
              onClick={onClick}
              disabled={selectCourse === undefined}
            />
          </div>
        )}
      </ApplicationBottom>
    </>
  );
};
