import { Banner } from 'design-system';

export const 검정료Box = ({ 검정료 }: { 검정료: number }) => {
  return (
    <section className="w-full">
      <p className="text-new-Sub-Title text-bew-gray-900">학원에서 결제할 금액</p>
      <ul className="py-20">
        <li className="flex justify-between">
          <p className="text-new-Body1-bold text-new-gray-900">검정료 (시험 응시 비용)</p>
          <span className="text-new-Body1-bold text-new-gray-900 text-right">
            {검정료.toLocaleString()}원
          </span>
        </li>
      </ul>
      <Banner
        description="보험비나 교재비 등 추가 비용이 발생할 수 있습니다."
        size="small"
        type="content-left"
        icon="info-outlined"
      />
    </section>
  );
};
