import { BaseLessonCode } from '@db/collections';
import { LESSON_PRIORITY } from 'shared-values';

type SimulationLessonGroupItem = {
  lessons: SimulationLesson[];
  isPopular?: boolean;
};

type SimulationLessonGroup = {
  [periodText: string]: SimulationLessonGroupItem;
};

export const getLessonNameFromCode = (code: LessonCode) => new BaseLessonCode(code).license || '';

export const groupSimulationLessonsByType = (
  registType: 'license' | 'training',
  periodType: 'day' | 'hour',
  sortedLessons: SimulationLesson[],
): SimulationLessonGroup => {
  return sortedLessons.reduce<SimulationLessonGroup>((acc, lesson) => {
    if (lesson.registType === registType && lesson.period.dateTrackingType === periodType) {
      const {
        period: { text: periodText },
        isPopular,
      } = lesson;
      if (!acc[periodText]) {
        acc[periodText] = { lessons: [], isPopular: false };
      }
      acc[periodText].lessons.push(lesson);
      acc[periodText].isPopular ||= isPopular;
    }
    return acc;
  }, {});
};

export const calcLessonOrderByCode = (code: LessonCode): number =>
  LESSON_PRIORITY[code] || Number.MAX_SAFE_INTEGER;

const LICENSE_PRIORITY: Record<
  | 'TWO_AUTO'
  | 'ONE_AUTO'
  | 'ONE_MANUAL'
  | 'TRAINING'
  | 'ONE_LARGE'
  | 'TRANSFORM_TWO_TO_ONE'
  | 'TWO_SMALL',
  number
> = {
  TWO_AUTO: 1,
  ONE_MANUAL: 2,
  ONE_AUTO: 3,
  TRAINING: 4,
  ONE_LARGE: 5,
  TRANSFORM_TWO_TO_ONE: 6,
  TWO_SMALL: 7,
};

export const calcLicenseOrderByCode = (
  code: 'TWO_AUTO' | 'ONE_MANUAL' | 'TRAINING' | 'ONE_LARGE' | 'TRANSFORM_TWO_TO_ONE' | 'TWO_SMALL',
): number => LICENSE_PRIORITY[code] || Number.MAX_SAFE_INTEGER;

export const calcSimulationLessonTime = (lesson: SimulationLesson): number => {
  const { period } = lesson;
  return period.dateTrackingType === 'day' ? (period.limitDays ?? 0) * 24 : period.limitHours ?? 0;
};

export const sortAcademyLessons = <L extends Lesson>(lessons: L[]): L[] => {
  return lessons.sort((a, b) => {
    const orderA = calcLessonOrderByCode(a.lessonInfo.lessonCode);
    const orderB = calcLessonOrderByCode(b.lessonInfo.lessonCode);
    return orderA !== orderB ? orderA - orderB : 0;
  });
};

export const sortSimulationLessons = <L extends SimulationLesson>(lessons: L[]): L[] => {
  return lessons.sort((a, b) => {
    const orderA = calcLessonOrderByCode(a.lessonInfo.lessonCode);
    const orderB = calcLessonOrderByCode(b.lessonInfo.lessonCode);
    const timeA = calcSimulationLessonTime(a);
    const timeB = calcSimulationLessonTime(b);
    return orderA !== orderB ? orderA - orderB : timeB - timeA;
  });
};

export const isSimulationLessons = (lessons: SimulationLesson[]): lessons is SimulationLesson[] => {
  return lessons[0].period !== undefined;
};

export const getSimulationLessonsByCategory = (lessons: SimulationLesson[]) => {
  const sortedLessons = sortSimulationLessons(lessons);
  const licenseDayLessons = groupSimulationLessonsByType('license', 'day', sortedLessons);
  const licenseHourLessons = groupSimulationLessonsByType('license', 'hour', sortedLessons);
  const trainingDayLessons = groupSimulationLessonsByType('training', 'day', sortedLessons);
  const trainingHourLessons = groupSimulationLessonsByType('training', 'hour', sortedLessons);
  return {
    licenseDayLessons,
    licenseHourLessons,
    trainingDayLessons,
    trainingHourLessons,
  };
};

export const groupLessonsByTypeAndSort = (lessons: SimulationLesson[]) => {
  const getLessonOrder = (code: LessonCode): number =>
    LESSON_PRIORITY[code] || Number.MAX_SAFE_INTEGER;

  const sortSimulationLessons = (items: SimulationLesson[]): SimulationLesson[] => {
    return items.sort((a, b) => {
      const getTime = (lesson: SimulationLesson) =>
        lesson.period.dateTrackingType === 'day'
          ? (lesson.period.limitDays ?? 0) * 24
          : lesson.period.limitHours ?? 0;

      const timeA = getTime(a);
      const timeB = getTime(b);

      const lessonOrderA = getLessonOrder(a.lessonInfo.lessonCode);
      const lessonOrderB = getLessonOrder(b.lessonInfo.lessonCode);

      return lessonOrderA !== lessonOrderB ? lessonOrderA - lessonOrderB : timeB - timeA;
    });
  };

  const groupLessonsByType = (
    registType: 'license' | 'training',
    periodType: 'day' | 'hour',
    sortedLessons: SimulationLesson[],
  ) => {
    const filteredByRegistType = sortedLessons.filter(
      (lesson) => lesson.registType === registType && lesson.period.dateTrackingType === periodType,
    );

    return filteredByRegistType.reduce((acc: SimulationLessonGroup, item) => {
      const {
        period: { text: periodText },
        isPopular,
      } = item;

      if (!acc[periodText]) {
        acc[periodText] = {
          lessons: [],
          isPopular: false,
        };
      }

      acc[periodText].lessons.push(item);
      acc[periodText].isPopular = isPopular ?? acc[periodText].isPopular;

      return acc;
    }, {});
  };

  const sortedLessons = sortSimulationLessons(lessons);
  const licenseDayLessons = groupLessonsByType('license', 'day', sortedLessons);
  const licenseHourLessons = groupLessonsByType('license', 'hour', sortedLessons);
  const trainingDayLessons = groupLessonsByType('training', 'day', sortedLessons);
  const trainingHourLessons = groupLessonsByType('training', 'hour', sortedLessons);

  const licenseDayLessonEntries = Object.entries(licenseDayLessons);
  const licenseHourLessonEntries = Object.entries(licenseHourLessons);
  const trainingDayLessonEntries = Object.entries(trainingDayLessons);
  const trainingHourLessonEntries = Object.entries(trainingHourLessons);

  const addPriceRangeToEntries = (
    entries: [
      string,
      {
        lessons: SimulationLesson[];
        isPopular?: boolean;
      },
    ][],
  ) => {
    return entries.map(([periodText, { lessons, isPopular }]) => [
      periodText,
      {
        lessons,
        isPopular,
      },
    ]);
  };

  const licenseLessonEntries = [
    ...addPriceRangeToEntries(licenseDayLessonEntries),
    ...addPriceRangeToEntries(licenseHourLessonEntries),
  ];
  const trainingLessonEntries = [
    ...addPriceRangeToEntries(trainingDayLessonEntries),
    ...addPriceRangeToEntries(trainingHourLessonEntries),
  ];

  return {
    licenseLessonEntries,
    trainingLessonEntries,
  };
};

export const getSummaryEntries = (entries: [string, SimulationLessonGroupItem][]) => {
  // 1. 먼저 제일 비싼 상품 2개를 뽑는다.
  const baseEntries = entries.slice(0, 2);

  // 2. 2개 뽑은 것 중 인기 상품이 하나도 없으면
  if (baseEntries.every((e) => !e[1].isPopular)) {
    // 3. 전체 중 제일 비싼 인기 상품을 뽑아서
    const firstPopularEntry = entries.find((e) => e[1].isPopular);

    // 4. 제일 비싼 상품과 인기 상품 중 제일 비싼 상품을 리턴.
    return firstPopularEntry ? [baseEntries[0], firstPopularEntry] : baseEntries;
  }

  // 5. 그 외엔 그냥 2개 리턴.
  return baseEntries;
};

export const getLessonEntries = (lessons: SimulationLesson[]) => {
  const { licenseDayLessons, licenseHourLessons, trainingDayLessons, trainingHourLessons } =
    getSimulationLessonsByCategory(lessons);
  const licenseDayLessonEntries = Object.entries(licenseDayLessons);
  const licenseHourLessonEntries = Object.entries(licenseHourLessons);
  const trainingDayLessonEntries = Object.entries(trainingDayLessons);
  const trainingHourLessonEntries = Object.entries(trainingHourLessons);

  const licenseLessonEntries = [...licenseDayLessonEntries, ...licenseHourLessonEntries];
  const trainingLessonEntries = [...trainingDayLessonEntries, ...trainingHourLessonEntries];

  return {
    licenseLessonEntries,
    summaryLicenseEntries: getSummaryEntries(licenseLessonEntries),
    trainingLessonEntries,
    summaryTrainingEntries: getSummaryEntries(trainingLessonEntries),
  };
};
