import { NewIcon, Spacer } from 'design-system';
import Link from 'next/link';

import { ChannelTalkButton } from '@components';
import { globalPreviousURL } from '@pages/academy/[id]/application';
import { useApplicationFunnel } from '@templates/Application/hooks';

interface CardProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  title: string;
  description: string;
}

const Card = ({ title, description, ...props }: CardProps) => {
  return (
    <article {...props} className="flex cursor-pointer gap-12 rounded-[16px] bg-white p-16">
      <div>
        <p className="text-new-Body2-bold">{title}</p>
        <Spacer className="h-[4px]" />
        <p className="text-new-Caption1-medium text-new-gray-500 whitespace-pre-line">
          {description}
        </p>
      </div>
      <NewIcon icon="arrowright-outlined" size={16} className="fill-new-gray-400" />
    </article>
  );
};
export const AcademyLinkCards = () => {
  const { academy } = useApplicationFunnel();

  return (
    <div className="bg-new-gray-50 absolute-justify-center border-l-1 border-r-1 fixed bottom-0 w-full max-w-[500px] border-[#e0e0d1] px-16">
      <Spacer className="h-[36px]" />
      <h2 className="text-new-Sub-Title">예약 전, 궁금한 게 있다면?</h2>
      <Spacer className="h-[4px]" />
      <h3 className="text-new-gray-500 text-new-Body2-medium">운전선생이 도와드릴게요!</h3>
      <Spacer className="h-[16px]" />
      <div className="flex gap-8 overflow-x-auto">
        <div className="shrink-0">
          <Link
            href={`/academy/${academy?.id}`}
            replace
            onClick={() => {
              globalPreviousURL.url = window.location.href;
            }}
          >
            <Card
              title={'🧐 학원 정보 더 알아보기'}
              description={'수강생들의 솔직한 후기도\n볼 수 있어요.'}
            />
          </Link>
        </div>
        <div className="shrink-0">
          <ChannelTalkButton pageName="학원 홈페이지">
            <Card
              title={'💬 채팅으로 문의하기'}
              description={'문의를 남겨주시면,\n실시간으로 답변해드려요.'}
            />
          </ChannelTalkButton>
        </div>
      </div>
      <Spacer className="h-[36px]" />
    </div>
  );
};
