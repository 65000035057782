import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { NewBoxButton, Spacer } from 'design-system';
import { AnimationControls, motion, useAnimationControls } from 'framer-motion';
import { formatDate } from 'shared-values';
import { useWindowSize } from 'usehooks-ts';

import { useApplicationFunnel } from '@templates/Application/hooks';

import { useFunnelStore } from '@hooks/use-funnel';

import { SelectedScheduleIndicator } from '../../SelectedScheduleIndicator';

interface TimeTableProps {
  timeList: SelectedTime[];
  selectedTime: SelectedTime | null;
  setSelectedTime: (selectedTime: SelectedTime | null) => void;
  // isAnimationAlreadyExecuted를 통해 애니메이션을 한 번만 실행하도록 함
  isAnimationAlreadyExecuted: MutableRefObject<boolean>;
}

const useControlHeight = (controls: AnimationControls) => {
  const { height } = useWindowSize();
  const { headerHeight, bottomHeight } = useFunnelStore();
  const funnelHeight = height > 0 ? height - headerHeight - bottomHeight : 0;
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContentHeight(node.offsetHeight);
    }
  }, []);

  useEffect(() => {
    controls.set({ paddingBottom: funnelHeight - contentHeight });
  }, [funnelHeight, contentHeight]);

  return contentRef;
};

const useScrollAnchor = (controls: AnimationControls, callbackWhenFinishAnimate: () => void) => {
  const { bottomHeight } = useFunnelStore();
  const { ref, inView, entry } = useInView({
    rootMargin: `0px 0px -${bottomHeight - 1}px 0px`,
    initialInView: false,
  });
  const [UIState, setUIState] = useState<'not_started' | 'expanding' | 'expanded' | 'shrinking'>(
    'not_started',
  );

  useEffect(() => {
    if (inView && UIState === 'expanding') {
      setUIState('expanded');
    } else if (UIState === 'expanded' && !inView) {
      controls.start({ paddingBottom: 0 });
      setUIState('shrinking');
      callbackWhenFinishAnimate();
    }
  }, [inView, UIState]);

  useEffect(() => {
    if (!entry || UIState !== 'not_started') return;

    entry.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setUIState('expanding');
  }, [entry]);

  return {
    Anchor: useMemo(() => {
      if (UIState === 'shrinking') return null;

      return <div className='h-1' ref={ref} />;
    }, [ref, UIState]),
    UIState,
  };
};

export const TimeTable = ({
  timeList,
  selectedTime,
  setSelectedTime,
  isAnimationAlreadyExecuted,
}: TimeTableProps) => {
  const controls = useAnimationControls();
  const { Anchor, UIState } = useScrollAnchor(controls, () => {
    isAnimationAlreadyExecuted.current = true;
  });

  const contentRef = useControlHeight(controls);

  const {
    lessonInfo,
    academy: { type },
  } = useApplicationFunnel<'DateSelect'>();

  const isGeneralAcademy = type === 'general-academy';

  return (
    <>
      <motion.div
        animate={!isAnimationAlreadyExecuted.current ? controls : undefined}
        transition={{ duration: 1 }}
        ref={contentRef}>
        <section className='flex flex-col gap-4 pt-40' id='time_table'>
          <h1 className='text-new-gray-900 text-new-Sub-Title'>교육 시작 시간을 선택해 주세요.</h1>
          {!isGeneralAcademy && (
            <p className='text-new-gray-500 text-new-Body2-medium '>
              학과교육은 <span className='text-new-DTRed-400'>총 3시간</span> 진행됩니다.
            </p>
          )}
        </section>
        <Spacer className='h-16' />
        <article className='grid w-full grid-cols-3 gap-4'>
          {timeList.map((time, index) => {
            const isSelected = time === selectedTime;
            const { startTimeString, status } = time;

            if (!status) return null;

            return (
              <NewBoxButton
                key={index}
                label={startTimeString}
                size='medium'
                state={isSelected ? 'selected' : status === 'finished' ? 'inactive' : 'active'}
                styles='outlined'
                onClick={() => {
                  if (isSelected) {
                    setSelectedTime(null);
                  } else {
                    setSelectedTime(time);
                  }
                }}
                className='w-full'
              />
            );
          })}
        </article>
        <Spacer className='h-[80px]' />
        {selectedTime && (
          <SelectedScheduleIndicator
            dateType='single'
            hasHeight={UIState !== 'expanded'}
            selectedSchedule={`${formatDate(selectedTime.date, 'YY.MM.DD')} ${
              selectedTime.startEndString
            }`}
          />
        )}
      </motion.div>
      {!isAnimationAlreadyExecuted.current ? Anchor : undefined}
    </>
  );
};
