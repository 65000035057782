import { useGetUser } from 'database';
import { BOXBUTTON_COLOR, BoxButton } from 'design-system';
import { useRouter } from 'next/router';
import { HOME_URI } from 'shared-values';

import { isAppApproaching } from '@utils/Common';

export const HomeButton = () => {
  const { resetUser } = useGetUser();

  const router = useRouter();

  const isAppApproach = isAppApproaching();

  // const { resetApplicationData, clearLocalStorage } = useApplicationFunnel({ isFirstScreen: true });

  return (
    <BoxButton
      text='홈으로 돌아가기'
      color={BOXBUTTON_COLOR.BLACK}
      onClick={() => {
        resetUser();
        // resetApplicationData();
        // clearLocalStorage();
        if (isAppApproach) {
          window.flutter_inappwebview.callHandler('ScreenMove', 'goToMain', HOME_URI);
        } else {
          router.replace('/');
        }
      }}
    />
  );
};
