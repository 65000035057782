import { LogEvent } from '@analytics';
import { getDiscountPrice } from '@db/base-models';
import { getHighestCouponWithPrice, useGetUser } from '@db/collections';
import { NewBoxButton, NewIcon, useOverlay } from 'design-system';

import { CouponBottomSheet } from '@components';
import { useApplicationFunnel } from '@templates/Application/hooks';

import { CouponsSelect } from './CouponsSelect';

export const CouponButton = ({
  결제금,
  selectedCoupon,
  selectableCoupons,
  receivableCoupons,
  onSelectCoupon,
}: {
  결제금: number;
  selectedCoupon?: CouponCode;
  selectableCoupons: CouponCode[];
  receivableCoupons: CouponCode[];
  onSelectCoupon: (coupon: CouponCode) => void;
}) => {
  const overlay = useOverlay();
  const { user } = useGetUser();
  const { lessonInfo } = useApplicationFunnel<'Purchase'>();
  const { lessonPeriodText } = lessonInfo;

  // 선택된 쿠폰이 있을 경우 -> 선택 모드
  if (selectedCoupon) {
    const { 할인금 } = getDiscountPrice({
      price: 결제금,
      discounts: [selectedCoupon.discount],
    });
    return (
      <div className="flex flex-col items-end gap-2">
        <div className="flex items-center justify-center gap-6">
          <div className="text-new-Body1-medium">-{할인금.toLocaleString()}원</div>
          <NewBoxButton
            label="변경"
            size="small"
            styles="outlined"
            onClick={() => {
              overlay.open(({ isOpen, close }) => (
                <CouponsSelect
                  isOpen={isOpen}
                  close={close}
                  coupons={selectableCoupons}
                  initialSelectedCoupon={selectedCoupon}
                  lessonPeriodText={lessonPeriodText}
                  onSelectedCoupon={(coupon) => {
                    onSelectCoupon(coupon);
                    close();
                  }}
                />
              ));
            }}
          />
        </div>
        <span className="text-new-Caption1-medium text-new-gray-500">{selectedCoupon.name}</span>
      </div>
    );
  }

  // 받을 수 있는 쿠폰이 있을 경우 -> 받기 모드
  if (user && receivableCoupons.length > 0) {
    const hightestPriceCoupon = getHighestCouponWithPrice(receivableCoupons, 결제금);
    const 받을수있는쿠폰할인금 = hightestPriceCoupon.discount.convertToPrice(결제금);

    return (
      <>
        <div className="text-Body4 text-DTRed-400">
          최대 {받을수있는쿠폰할인금.toLocaleString()}원
        </div>
        <button
          className="bg-DTRed-400 flex items-center gap-2 rounded-[16px] px-10 py-2"
          onClick={() => {
            overlay.open(({ isOpen, close }) => (
              <CouponBottomSheet
                isOpen={isOpen}
                close={close}
                coupons={receivableCoupons}
                onClickReceiveCoupon={(coupon) => {
                  LogEvent.InsightfulData.couponReceive(coupon.source, '결제 확인 페이지');
                }}
                onClickReceiveAllCoupons={() => {
                  onSelectCoupon(hightestPriceCoupon);
                }}
                downloadable={false}
              />
            ));
          }}
        >
          <NewIcon icon="download-outlined" size={16} className="fill-new-DTRed-400" />
          <div className="text-Body6 text-white">쿠폰받기</div>
        </button>
      </>
    );
  }

  // 받을 수 있는 쿠폰이 없음
  return (
    <div className="flex flex-col items-end gap-2 ">
      <div className="text-Body1">0원</div>
      <span className="text-Body6 text-gray-400">사용할 수 있는 쿠폰이 없어요.</span>
    </div>
  );
};
