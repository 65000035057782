export const PrivateInfoProvideTerm = ({ academyName }: { academyName: string }) => {
  return (
    <div className='text-D2 overflow-hidden p-20'>
      <div className='flex'>
        <div className='bg-Background border-Grey w-[120px] border-[0.5px] p-10'>제공받는자</div>
        <div className='border-Grey w-full border-[0.5px] p-10'>{academyName}</div>
      </div>
      <div className='flex'>
        <div className='bg-Background border-Grey w-[120px] border-[0.5px] p-10'>제공 목적</div>
        <div className='border-Grey w-full border-[0.5px] p-10'>
          예약, 구매한 상품, 서비스의 제공 및 계약의 이행 (이용자 및 이용정보 확인), 민원처리 등
          소비자 분쟁 해결
        </div>
      </div>
      <div className='flex'>
        <div className='bg-Background border-Grey w-[120px] border-[0.5px] p-10'>제공 항목</div>
        <div className='border-Grey w-full border-[0.5px] p-10'>
          예약번호, 예약자 정보 (예약자명, 휴대폰 번호, 생년월일), 주소
        </div>
      </div>
      <div className='flex'>
        <div className='bg-Background border-Grey w-[120px] border-[0.5px] p-10'>
          이용 및 보유 기간
        </div>
        <div className='border-Grey w-full border-[0.5px] p-10'>
          개인정보 이용목적 달성 시까지
          <br />
          단, 관계법령에 의하여 보존할 필요성이 있는 경우 그 시점까지 보존 후 지체 없이 파기
        </div>
      </div>
      <div className='mt-10'>
        * 위의 개인정보 제 3자 제공에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우
        서비스 이용이 제한됩니다.
      </div>
    </div>
  );
};
