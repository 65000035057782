import { loadingAtom } from '@jotaiStore';
import { IssuedCoupon, useGetUser } from 'database';
import { Icon, NewIcon } from 'design-system';
import { useAtom } from 'jotai';
import { formatDate } from 'shared-values';

interface CouponCardProps {
  coupon: CouponCode | IssuedCoupon;
  onClickCoupon?: (coupon: IssuedCoupon | CouponCode) => void;
  onClickRedirectButton?: (coupon: IssuedCoupon | CouponCode) => void;
  onFail?: () => void;
  onClickDownloadCoupon?: (coupon: IssuedCoupon | CouponCode) => void;
  isDisabled?: boolean;
  isSelected?: boolean;
  downloadable?: boolean;
}

export const CouponCard = (props: CouponCardProps) => {
  const {
    coupon,
    onClickCoupon,
    onClickRedirectButton,
    onFail,
    onClickDownloadCoupon,
    isDisabled = false,
    isSelected = false,
    downloadable = false,
  } = props;

  const [isLoading, setIsLoading] = useAtom(loadingAtom);
  const { user } = useGetUser();

  const { name, expirationDate, isExpired, source, discount } = coupon;

  const isUsed = coupon instanceof IssuedCoupon ? coupon.isUsed : false;

  const isCouponDisabled = isExpired || isUsed || isDisabled;

  const isAlreadyParticipatedCouponEvent = user?.isAlreadyParticipatedCouponEvent(source);

  const periodTexts = discount.lessonConditions.map(
    (lessonCondition) => lessonCondition.periodText,
  );
  const joinedPeriodTexts = periodTexts.join(', ');

  const isFirstComeCoupon = source === 'first-come';

  return (
    <>
      <article
        className={`py-18 rounded-[24px] border-[1.6px] px-24 ${
          isSelected ? 'border-DTYellow-400 bg-[rgba(255,235,55,0.1)]' : 'border-gray-200 bg-white'
        }
      `}
        onClick={() => onClickCoupon && !isDisabled && onClickCoupon(coupon)}
      >
        <div className={`flex items-center justify-between`}>
          <section
            className={`flex flex-col gap-2 ${
              isCouponDisabled ? 'text-gray-400' : 'text-gray-850'
            }`}
          >
            <h2
              className={`text-Title1  ${
                isCouponDisabled ? 'text-gray-400' : 'text-new-DTRed-400'
              }`}
            >
              {discount.discountAmount}
            </h2>
            <h1 className={`text-Body6 ${isCouponDisabled ? 'text-gray-400' : 'text-gray-850'}`}>
              {name}
            </h1>
            {!isFirstComeCoupon && (
              <h3
                className={`text-Detail2 ${isCouponDisabled ? 'text-gray-400' : 'text-gray-600'}`}
              >
                유효기간 : ~{formatDate(expirationDate, 'YY.MM.DD')}까지
              </h3>
            )}
            <section className="text-Detail2">
              {discount.lessonConditions.length > 0 && joinedPeriodTexts.length > 0 && (
                <h4 className="text-gray-400">{joinedPeriodTexts} 이용권 한정</h4>
              )}
            </section>
          </section>
          {downloadable && (
            <button
              onClick={async (e) => {
                e.stopPropagation();
                if (isCouponDisabled || isLoading) return;
                if (!user) {
                  onFail && onFail();
                  return;
                }
                onClickDownloadCoupon && onClickDownloadCoupon(coupon);
                setIsLoading(true);
                await user.addCoupon({ couponCode: coupon.get() });
                setIsLoading(false);
              }}
              className={isDisabled ? 'cursor-default' : ''}
            >
              <figure className="text-Body6 flex flex-col items-center justify-center gap-[6px]">
                <Icon
                  icon={`${isCouponDisabled ? 'receive-completed' : 'coupon-download-active'}`}
                  size={48}
                />
                {isAlreadyParticipatedCouponEvent && (
                  <figcaption className="text-gray-400">발급 완료</figcaption>
                )}
              </figure>
            </button>
          )}
          {!isCouponDisabled &&
            !downloadable &&
            !isSelected &&
            !onClickCoupon &&
            onClickRedirectButton && (
              <button
                className="mt-12 flex cursor-pointer items-center justify-center"
                onClick={() => onClickRedirectButton && onClickRedirectButton(coupon)}
              >
                <NewIcon icon="arrowright-outlined" size={30} className="fill-new-gray-900" />
              </button>
            )}
        </div>
      </article>
    </>
  );
};
