import { useToast } from 'design-system';
import Image from 'next/image';
import { cn } from 'tailwind-config';

export const RegistTypeCard = ({
  isSelected,
  isInactive,
  inactiveMessage,
  title,
  description,
  image,
  onClick,
}: {
  isSelected: boolean;
  isInactive: boolean;
  inactiveMessage: string;
  title: string;
  description: string;
  image: { src: string; alt: string };
  onClick: () => void;
}) => {
  const { openToast } = useToast();

  return (
    <article
      className={cn(
        isSelected ? 'border-new-DTYellow-400 bg-DTYellow-400/10' : 'border-new-gray-200',
        isInactive && 'border-new-gray-200 text-new-gray-500',
        'py-18 flex flex-col items-center justify-between gap-[10px] rounded-[16px] border-2',
      )}
      onClick={() => {
        if (isInactive) {
          openToast({
            title: inactiveMessage,
          });
          return;
        }
        onClick();
      }}
    >
      <Image
        src={image.src}
        alt={image.alt}
        width={64}
        height={64}
        className={cn(isInactive && 'opacity-50 grayscale filter')}
      />
      <div className="flex flex-col gap-4 text-center">
        <p className="text-new-Body2-bold">{title}</p>
        <p className="text-new-Caption1-medium text-new-gray-500">{description}</p>
      </div>
    </article>
  );
};
