import { backupDB, db } from 'firebase-config';
import { doc, setDoc } from 'firebase/firestore';

export const addReceipt = async ({
  orderId,
  receipt,
  visitTrainingReceipt,
  itemPrice,
  discountPrice,
  isKeyInPurchase,
  originalUrl,
  gcooEvent,
  운전선생할인금,
  시뮬레이션할인금,
  discounts,
  firstComeCouponCode,
}: {
  orderId: string;
  receipt: Omit<ReceiptData, 'id'>;
  visitTrainingReceipt?: VisitTrainingReceiptData;
  itemPrice: number;
  discountPrice: number;
  isKeyInPurchase: boolean;
  originalUrl?: string;
  gcooEvent: {
    participated: boolean;
    maintainDate: Date;
  };
  운전선생할인금: number;
  시뮬레이션할인금: number;
  discounts: Discount[];
  firstComeCouponCode: string;
}) => {
  await setDoc(doc(db, 'OrderRequest', orderId), {
    amount: itemPrice,
    discountPrice,
    receipt,
    isKeyInPurchase,
    originalUrl,
    gcooEvent,
    운전선생할인금,
    시뮬레이션할인금,
    discounts: discounts ? discounts.map((v) => v.get()) : [],
    firstComeCouponCode,
    visitTrainingReceipt: visitTrainingReceipt ? visitTrainingReceipt : null,
  });
  await setDoc(doc(backupDB, 'OrderRequest', orderId), {
    amount: itemPrice,
    discountPrice,
    receipt,
    isKeyInPurchase,
    originalUrl,
    gcooEvent,
    운전선생할인금,
    시뮬레이션할인금,
    discounts: discounts ? discounts.map((v) => v.get()) : [],
    firstComeCouponCode,
    visitTrainingReceipt: visitTrainingReceipt ? visitTrainingReceipt : null,
  });
};
