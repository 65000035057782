import { ReactElement } from 'react';

import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

interface TextProps {
  children: string;
  underlineTexts?: string[];
  boldTexts?: string[];
  className?: string;
}

/** @deprecated 더 이상 사용하지 않는 컴포넌트 */
export const Text = ({ children, underlineTexts, boldTexts, className }: TextProps) => {
  const splitUnderline = (text: string) => {
    if (!underlineTexts) return text;

    let resultText = '';

    let targetText: string = text;
    underlineTexts.map((underlineText) => {
      const splitArray = targetText.split(underlineText);
      resultText += splitArray[0] + `$u${underlineText}$u`;
      targetText = splitArray[1];
    });

    resultText += targetText;

    return resultText;
  };

  const splitBold = (text: string): string => {
    if (!boldTexts) return text;

    let resultText = '';

    let targetText: string = text;
    boldTexts.map((boldText) => {
      const splitArray = targetText.split(boldText);
      resultText += splitArray[0] + `$b${boldText}$b`;
      targetText = splitArray[1];
    });

    resultText += targetText;

    return resultText;
  };

  const attachHTMLTag = (text: string) => {
    const textArray = [];
    const underlineSplitArray = text.split('$u');
    for (let i = 0; i < underlineSplitArray.length; i++) {
      if (i % 2 == 1) {
        const boldArray = [];

        const boldSplitArray = underlineSplitArray[i].split('$b');
        for (let i = 0; i < boldSplitArray.length; i++) {
          if (i % 2 == 1) {
            boldArray.push(
              <div className='font-bold' key={uuidv4()}>
                {boldSplitArray[i]}
              </div>,
            );
          } else {
            boldArray.push(<div>{boldSplitArray[i]}</div>);
          }
        }

        textArray.push(
          <div className='flex underline decoration-yellow underline-offset-4'>{boldArray}</div>,
        );
      } else {
        textArray.push(<div>{underlineSplitArray[i]}</div>);
      }
    }

    return textArray;
  };

  const breakLine = (textArray: ReactElement[]) => {
    return textArray.map((text, index) => {
      const innerText = text.props.children;
      if (innerText.includes('/n')) {
        if (innerText === '/n') {
          return <div key={uuidv4()} className="h-0 basis-full" />;
        }
        const breakArray = innerText.split('/n');
        return [
          breakArray[0],
          <div key={uuidv4()} className="h-0 basis-full" />,
          breakArray[1],
        ];
      } else {
        return text;
      }
    });
  };

  const renderText = (text: string) => {
    const resultArray = attachHTMLTag(text);
    return breakLine(resultArray);
  };

  return (
    <div
      className={classNames(
        'flex flex-wrap whitespace-pre-wrap text-[22px] font-regular',
        className,
      )}
      style={{ fontFamily: 'AppleSDGothicNeo' }}>
      {renderText(splitBold(splitUnderline(children)))}
    </div>
  );
};
