import { motion } from 'framer-motion';

export const CheckButton = ({
  onClickFalse,
  onClickTrue,
  falseText,
  trueText,
}: {
  onClickFalse: () => void;
  onClickTrue: () => void;
  falseText: string;
  trueText: string;
}) => {
  return (
    <div className='flex gap-10'>
      <motion.button
        whileTap={{
          scale: 0.97,
          backgroundColor: 'black',
          color: 'white',
        }}
        onClick={onClickFalse}
        className='text-Bt2 w-full rounded-md bg-gray-750 py-12 text-white'>
        {falseText}
      </motion.button>
      <motion.button
        whileTap={{ scale: 0.97, backgroundColor: '#2B2929', color: 'white' }}
        onClick={onClickTrue}
        className='text-Bt2 w-full rounded-md bg-gray-850 py-12 text-white'>
        {trueText}
      </motion.button>
    </div>
  );
};
