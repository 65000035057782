import { StateCreator } from 'zustand';

import { ApplicationData } from '@templates/Application/hooks';

export interface SelectRegistTypeStore {
  selectedRegistType: RegistType | null;
  setSelectedRegistType: (selectedRegistType: RegistType | null) => void;
}

export const selectRegistTypeStore: StateCreator<ApplicationData, [], [], SelectRegistTypeStore> = (
  set,
  get,
) => ({
  selectedRegistType: null,
  setSelectedRegistType: (selectedRegistType: RegistType | null) => set({ selectedRegistType }),
});
