import { useEffect, useMemo } from 'react';

import {
  getDiscountPrice,
  useGetAcademyCouponCodes,
  useGetEventRoom,
  useGetFirstComeCouponCode,
  useGetUser,
} from 'database';
import { Banner, Spacer } from 'design-system';
import { useRouter } from 'next/router';
import { DEPOSIT, convertTo만원단위 } from 'shared-values';

import { Loading } from '@components/Loading';
import { InfoPopover } from '@components/Popover';
import { isGlobalFromOnsiteEvent, isGlobalLinkFromAcademy } from '@pages/academy/[id]/application';
import { useApplicationFunnel } from '@templates/Application/hooks';

import { usePageQuery } from '@hooks/use-handle-url';

import { selectBestCoupons } from '../../utils';
import { CouponButton, 검정료Box, 차액Box } from './components';

export const 선착순쿠폰학원홈페이지허용학원 = ['mdoxbMRnxWMSOv9TOK4Q'];

const CouponBoxes: { name: string; source: DiscountSource }[] = [
  {
    name: '실내운전연습장 쿠폰',
    source: 'simulation',
  },
  {
    name: '운전선생 쿠폰',
    source: 'dt',
  },
];

const CouponsBox = ({ 결제금 }: { 결제금: number }) => {
  const { user } = useGetUser();
  const {
    lessonInfo,
    selectedCoupons,
    setSelectedCoupons,
    academy,
    setDiscountResult,
    setDiscounts,
  } = useApplicationFunnel<'Purchase'>();
  const { data: firstCoupon } = useGetFirstComeCouponCode(academy.id, false);
  const { id: academyId } = academy;
  const router = useRouter();
  const { forceUseFirstCome } = router.query;

  const { lessonPeriodText, lessonRegistType, lessonCode, lessonPrice } = lessonInfo;

  const discountCondition: DiscountCondition = useMemo(
    () => ({
      periodText: lessonPeriodText,
      registType: lessonRegistType,
      lessonCode,
      academyId: academyId as string,
    }),
    [lessonRegistType, lessonCode, academyId],
  );

  const {
    data = [],
    isInitialLoading,
    isError,
  } = useGetAcademyCouponCodes({
    academyId: academyId as string,
    condition: discountCondition,
  });

  const coupons = useMemo(
    () => [
      ...(firstCoupon &&
      (Boolean(forceUseFirstCome) ||
        (firstCoupon.canUse &&
          (선착순쿠폰학원홈페이지허용학원.includes(academyId) || !isGlobalLinkFromAcademy))) &&
      firstCoupon.discount.canApply(discountCondition) &&
      !isGlobalFromOnsiteEvent
        ? [firstCoupon]
        : []),
      ...(user
        ? user
            .getApplicableCoupons(discountCondition)
            .filter((coupon) => coupon.isValid)
            .map((coupon) => coupon.toCouponCode())
        : []),
    ],

    [academyId, firstCoupon, user, discountCondition],
  );

  /**
   * initial selected coupons
   */
  useEffect(() => {
    const newSelectedCoupons = selectBestCoupons(coupons, lessonPrice);
    /**
     * 241212 (노크쿠폰을 보유한 경우) & (선착순 쿠폰이 1만원이 넘어가는 경우) => 중복 적용해야 하는 요구가 있었음.
     */
    const knockCoupon = coupons.find((coupon) => coupon.source === 'KNOCK-PRIVATE');
    const firstComeCoupon = newSelectedCoupons.find((coupon) => coupon.source === 'first-come');

    setSelectedCoupons([
      ...newSelectedCoupons,
      ...(firstComeCoupon && firstComeCoupon.discount.discountPrice > 10000 && knockCoupon
        ? [knockCoupon]
        : []),
    ]);
  }, [coupons]);

  useEffect(() => {
    const discounts: Discount[] = selectedCoupons.map((coupon) => coupon.discount);
    const discountResult = getDiscountPrice({
      price: 결제금,
      discounts,
    });
    setDiscountResult(discountResult);
    setDiscounts(discounts);
  }, [selectedCoupons]);

  if (isInitialLoading || isError) return <Loading />;

  return (
    <>
      {CouponBoxes.filter((box) => {
        // academyInfo.type이 "academy"이면 "simulation" 소스를 가진 쿠폰은 제외
        return academy.isSimulation || box.source !== 'simulation';
      }).map((couponBox, index) => {
        const { name, source } = couponBox;
        // selectedCoupons는 각 타입별로 하나씩만 존재함.
        const selectedCoupon = selectedCoupons.find(
          (coupon) => coupon.discount.discountSource === source && !coupon.isExpired,
        );

        const selectableCoupons = coupons.filter(
          (coupon) => coupon.discount.discountSource === source && !coupon.isExpired,
        );
        const receivableCoupons = academy.isSimulation
          ? data.filter((coupon) => coupon.discount.discountSource === source && !coupon.isExpired)
          : [];

        return (
          <div
            className="flex w-full flex-row items-start justify-between"
            key={`coupon-box-${index}`}
          >
            <div className="text-new-Body2-medium text-new-gray-500 py-2">{name}</div>
            <div className="flex flex-col items-end">
              <div className="flex items-center gap-6">
                {selectedCoupons.some((v) => v.source === 'KNOCK-PRIVATE') &&
                selectedCoupons.some((v) => v.source === 'first-come') ? (
                  <div className="flex flex-col items-end gap-2">
                    <p>
                      -
                      {selectedCoupons
                        .reduce((acc, v) => acc + v.discount.discountPrice, 0)
                        .toLocaleString()}
                      원
                    </p>
                    <p className="text-new-Caption1-medium text-new-gray-500">
                      (선착순 {convertTo만원단위(firstCoupon?.discount.discountPrice ?? 0)} + 노크
                      쿠폰{' '}
                      {convertTo만원단위(
                        selectedCoupons.find((v) => v.source === 'KNOCK-PRIVATE')?.discount
                          .discountPrice ?? 0,
                      )}
                      )
                    </p>
                  </div>
                ) : (
                  <CouponButton
                    결제금={결제금}
                    selectableCoupons={selectableCoupons}
                    receivableCoupons={receivableCoupons}
                    selectedCoupon={selectedCoupon}
                    onSelectCoupon={(coupon) => {
                      const excludeCoupons = selectedCoupons.filter(
                        (coupon) => coupon.discount.discountSource !== source,
                      );
                      setSelectedCoupons([...excludeCoupons, coupon]);
                    }}
                  />
                )}
              </div>
              <div className="text-new-Caption1-medium text-new-gray-400 self-end">
                * 운전선생에서 자체 진행하는 할인입니다.
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const EventRoomBox = ({ 결제금 }: { 결제금: number }) => {
  const { setDiscountResult, setDiscounts } = useApplicationFunnel<'Purchase'>();

  const { user } = useGetUser();
  const { data: eventRoom } = useGetEventRoom(user?.eventRoomId);

  useEffect(() => {
    if (!eventRoom) return;

    setDiscounts(discounts);
    setDiscountResult(discountResult);
    // 결제 도중 인원이 달라질 수 있으므로, 방 점보가 변경될 때마다 재계산
  }, [eventRoom]);

  if (!eventRoom) return <Loading />;

  const {
    eventAcademy: { togetherEventTable },
    readyUserCount,
  } = eventRoom;

  const discounts: Discount[] = [eventRoom.discount];
  const discountResult = getDiscountPrice({
    price: 결제금,
    discounts,
  });

  return (
    <div className="flex w-full flex-row items-start justify-between">
      <div className="text-new-Body2-medium text-new-gray-500 py-2">
        {togetherEventTable
          ? `동시 등록 할인(${readyUserCount}인)`
          : `같이 취득 할인(${readyUserCount}인)`}
      </div>
      <div>{discountResult.할인금 > 0 ? `-${discountResult.할인금.toLocaleString()}` : 0}원</div>
    </div>
  );
};

export const PriceBox = () => {
  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();

  const { academy, discountResult, lessonInfo } = useApplicationFunnel<'Purchase'>();

  const { lessonPrice, lessonAdditionalPrice, lessonDiscountPrice, lessonTotalPrice } = lessonInfo;

  const { 원금, 최종금 } = discountResult || { 원금: 0, 최종금: 0 };

  const is전액결제 = academy.paymentType === 'entire';

  const 결제금 = Number(is전액결제 ? lessonPrice : DEPOSIT);

  const is면허캠프 = lessonInfo.lessonCode === 'TWO_AUTO_CAMP';

  return (
    <section>
      <div className="flex flex-col gap-16">
        <div className="text-new-Sub-Title text-new-gray-900 pb-4">{`${
          !is전액결제 || lessonAdditionalPrice > 0 ? '지금 결제할 금액' : '결제 금액'
        }`}</div>
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex gap-2">
            <p className="text-new-Body2-medium text-new-gray-500">{`${
              !is전액결제
                ? '선결제금'
                : `${academy.isSimulation ? '상품' : '수업'} 가격 ${
                    lessonAdditionalPrice > 0 ? '(검정료 제외)' : ''
                  }`
            }`}</p>
            {is전액결제 && (
              <InfoPopover content="해당 학원 예약 시 운전선생을 통해 먼저 결제하는 금액입니다." />
            )}
          </div>
          <span className="text-new-Body1-medium">
            {(!is전액결제 ? 원금 : lessonTotalPrice - lessonAdditionalPrice).toLocaleString()}원
          </span>
        </div>
        {lessonDiscountPrice > 0 && (
          <div className="flex w-full flex-row items-center justify-between">
            <p className="text-new-Body2-medium text-new-gray-500">운전학원 전용 할인</p>
            <span className="text-new-Body1-medium">-{lessonDiscountPrice.toLocaleString()}원</span>
          </div>
        )}
        {applicationMode === 'together-purchase' ? (
          <EventRoomBox 결제금={결제금} />
        ) : (
          <CouponsBox 결제금={결제금} />
        )}
        <hr className="border-new-gray-100 -mx-16 border" />
        <div className="flex w-full flex-row items-center justify-between">
          <div className="text-new-Body2-bold text-new-gray-900">최종 결제금</div>
          <div className="text-new-Body1-bold text-new-DTRed-400">
            = {최종금.toLocaleString()}원
          </div>
        </div>
      </div>
      {lessonAdditionalPrice > 0 ? (
        <>
          <Spacer className="h-32" />
          <Banner
            description="해당 학원은 검정료(응시 비용)를 현금으로 납부받고 있어, 학원 방문 후 직접 결제하셔야 합니다."
            size="small"
            type="content-left"
            icon="info-outlined"
          />
          <Spacer className="h-32" />
          <hr className="border-new-gray-100 border-6 -mx-16" />
          <Spacer className="h-32" />
          <검정료Box 검정료={lessonAdditionalPrice} />
        </>
      ) : (
        is전액결제 &&
        !academy.isSimulation &&
        !is면허캠프 && (
          <>
            <Spacer className="h-20" />
            <Banner
              description="보험비나 교재비 등 추가 비용이 발생할 수 있습니다."
              size="small"
              type="content-left"
              icon="info-outlined"
            />
          </>
        )
      )}
      {!is전액결제 && (
        <>
          <Spacer className="h-32" />
          <hr className="border-new-gray-100 border-6 -mx-16" />
          <Spacer className="h-32" />
          <차액Box lessonPrice={lessonPrice} />
        </>
      )}
    </section>
  );
};
