import { Loading } from '@components';

import { useFunnel } from '@hooks/use-funnel';
import { Funnel } from '@hooks/use-funnel/components';

import { ApplicationHeader } from './components';
import { AcademyLinkFloatingBar } from './components/AcademyLinkFloatingBar';
import { ApplicationBottom } from './components/ApplicationBottom';
import {
  useApplicationAnalytics,
  useApplicationFunnel,
  useHandleChangeScreen,
  useSyncLessonInfoOnServer,
} from './hooks';
import { DateSelect, LessonsSelect, Purchase, RegistTypeSelect } from './screens';

export const Application = () => {
  const screens = useHandleChangeScreen();
  useApplicationAnalytics();
  useSyncLessonInfoOnServer();

  const { academy, isLoading } = useApplicationFunnel();

  const { currentScreen } = useFunnel({
    screens: screens,
  });

  if (isLoading || !academy) return <Loading />;

  return (
    <Funnel>
      <ApplicationHeader />
      {/* Funnel.Bottom이 여러번 사용됐을 때, 나중에 사용된 것을 우선적으로 사용하기 위해 공용으로 사용하는 ApplicationBottom을 먼저 위치시킴 */}
      <ApplicationBottom />
      <AcademyLinkFloatingBar />
      <div className="px-16">
        {currentScreen === 'RegistTypeSelect' ? (
          <RegistTypeSelect />
        ) : currentScreen === 'LessonsSelect' ? (
          <LessonsSelect />
        ) : currentScreen === 'DateSelect' ? (
          <DateSelect />
        ) : currentScreen === 'Purchase' ? (
          <Purchase />
        ) : null}
      </div>
    </Funnel>
  );
};
