import { useEffect } from 'react';

import { useToast } from 'design-system';

import { useApplicationFunnel } from '@templates/Application/hooks';

import {
  CampDateSelect,
  DateRangeCalendar,
  SingleDateCalendar,
  TimeSelectionCalendar,
} from './components';

export const BlockAcademyIds = ['ciaUcB5L4ziqHFcHfJbE', '7TMTGmecRjTAMxa5LVst'];

const useBlockReservation = (academyId: string) => {
  const { openToast } = useToast();
  useEffect(() => {
    if (BlockAcademyIds.includes(academyId)) {
      openToast({
        title: '현재 학원의 가능한 모든 날짜가 예약 마감되었습니다.',
        yPosition: 80,
      });
    }
  }, [academyId]);
};

type campAcademyName = '동송' | '하예서부' | '설악산' | '강릉' | '원영' | '현대';

export const DateSelect = ({ isFromOnsiteEvent }: { isFromOnsiteEvent?: boolean }) => {
  const { lessonInfo, academy } = useApplicationFunnel<'DateSelect'>();

  const { isSimulation, id: academyId, name } = academy;

  useBlockReservation(academyId);

  const isFirstVisit = lessonInfo?.arrivalMethod === 'firstVisit';

  const is면허캠프 = lessonInfo.lessonCode === 'TWO_AUTO_CAMP';

  if (!lessonInfo) return null;

  if (isSimulation) return <SingleDateCalendar />; // 날짜선택 O 범위 X 시간 X
  if (isFirstVisit) return <DateRangeCalendar />; // 날짜 O, 범위 O, 시간 X
  if (is면허캠프) return <CampDateSelect academyName={name as campAcademyName} />; // 버튼으로 날짜 선택-면허캠프
  return <TimeSelectionCalendar isFromOnSiteEvent={isFromOnsiteEvent} />; // 날짜 O, 범위 X, 시간 O
};
