import { Banner, Spacer } from 'design-system';
import { DEPOSIT } from 'shared-values';

export const 차액Box = ({ lessonPrice }: { lessonPrice: number }) => (
  <section className="w-full">
    <p className="text-new-Sub-Title text-bew-gray-900">학원에서 결제할 금액</p>
    <ul className="py-20">
      <li className="flex justify-between">
        <p className="text-new-Body2-medium text-new-gray-500">수업 가격</p>
        <span className="text-new-Body1-medium text-new-gray-900 text-right">
          {lessonPrice.toLocaleString()}원
        </span>
      </li>
      <Spacer className="h-16" />
      <li className="flex justify-between">
        <p className="text-new-Body2-medium text-new-gray-500">운전선생 결제금</p>
        <span className="text-new-Body1-medium text-new-gray-900 text-right">
          {DEPOSIT.toLocaleString()}원
        </span>
      </li>
      <Spacer className="h-16" />
      <hr className="border-new-gray-100 -mx-16 border" />
      <Spacer className="h-16" />
      <li className="flex justify-between pt-[10px]">
        <p className="text-new-Body2-bold text-new-gray-900">학원 방문 후 결제금</p>
        <span className="text-new-Caption1-medium text-new-gray-500 text-right">
          {lessonPrice.toLocaleString()} - {DEPOSIT.toLocaleString()}
        </span>
      </li>
      <li className="text-new-Body1-bold text-new-gray-900 flex justify-end text-right">
        = {(lessonPrice - DEPOSIT).toLocaleString()}원
      </li>
    </ul>
    <Banner
      description="보험비나 교재비 등 추가 비용이 발생할 수 있습니다."
      size="small"
      type="content-left"
      icon="info-outlined"
    />
  </section>
);
