import { useEffect, useState } from 'react';

import { useGetUser } from '@db/collections';
import { BOXBUTTON_COLOR, Banner, BottomSheet, NewBoxButton, Spacer } from 'design-system';

import { Input } from '@components';

import { BOTTOM_SHEET_ONE_HEIGHT } from '@constants';
import { useWindowSize } from '@hooks';

import { VerificationButton, VisitorInfo } from './components';
import { PostCode } from './components/PostCode';

interface PurchasePageProps {
  userVerified: boolean;
}

export const UserInfo = ({ userVerified }: PurchasePageProps) => {
  const { user } = useGetUser();

  const { height } = useWindowSize({
    isTopNavbarHeight: true,
  });

  const [addressPage, setAddressPage] = useState<PurchasePage | null>(null);
  const [userAddress, setUserAddress] = useState('');
  const [userDetailAddress, setUserDetailAddress] = useState<string>('');

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleAddressSheet = (props: PurchasePage | null) => {
    setOpenModal((prev) => !prev);
    setAddressPage(props);
  };

  const { address, detailAddress } = user || {};

  useEffect(() => {
    if (user) {
      setUserAddress(address ?? '');
      setUserDetailAddress(detailAddress ?? '');
    }
  }, [user]);

  if (!user) return null;

  const postAddress = async () => {
    setOpenModal(false);
    if (!user.uid) return;
    await user.updateAddress({
      address: userAddress,
      detailAddress: userDetailAddress,
    });
  };

  return (
    <>
      <div className="py-32">
        <h4 className="text-new-Sub-Title text-new-gray-900">
          <span className="text-new-DTRed-400 text-new-Sub-Title">*</span>예약자 필수 정보
        </h4>
        <Spacer className="h-20" />
        <div className="flex  flex-col gap-14">
          {!userVerified && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-8">
                <p className="text-new-Body2-medium text-new-gray-500 min-w-[54px]">본인인증</p>
                {user.isVerified ? (
                  <p className="text-new-Body2-bold text-new-DTGreen-500">인증완료</p>
                ) : (
                  <p className="text-new-Body2-medium text-new-gray-500">본인인증이 필요해요.</p>
                )}
              </div>
              {!user.isVerified && <VerificationButton />}
            </div>
          )}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-8">
              <p className="text-new-Body2-medium text-new-gray-500 min-w-[54px]">주소입력</p>
              {address && address.length > 0 ? (
                <p className="text-new-Body2-medium text-new-gray-900">
                  {address + ` (${detailAddress})`}
                </p>
              ) : (
                <p className="text-new-Body2-medium text-new-gray-500">주소입력이 필요해요.</p>
              )}
            </div>
            <NewBoxButton
              styles="filled-black"
              size="small"
              onClick={() => handleAddressSheet('postcode')}
              label="주소검색"
            />
          </div>
          <VisitorInfo />
        </div>
      </div>
      {addressPage !== null && (
        <div>
          <BottomSheet
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
            header={{
              title: '주소를 입력해주세요',
            }}
            main={
              <div
                className="relative flex w-full flex-col items-center"
                style={{ height: height - BOTTOM_SHEET_ONE_HEIGHT }}
              >
                {addressPage === 'address' ? (
                  <>
                    <Banner
                      description={
                        <>
                          <span className="text-DTRed-400">주민등록증</span>에 기입되어 있는 정보로
                          입력해주세요.
                        </>
                      }
                      size="small"
                      type="content-left"
                      icon="info-outlined"
                    />
                    <Spacer className="h-32" />
                    <div className="flex w-full flex-col">
                      <p className="text-new-Caption1-bold text-new-gray-600">주소</p>
                      <Input
                        label={<div className="text-new-Body1-medium text-new-gray-400 mb-5"></div>}
                        readOnly={true}
                        placeholder="주소를 검색해주세요."
                        value={userAddress}
                        buttonProps={{
                          label: '주소검색',
                          onClick: () => setAddressPage('postcode'),
                        }}
                      />
                    </div>
                    <Spacer className="h-32" />
                    <div className="flex w-full flex-col">
                      <p className="text-new-Caption1-bold text-new-gray-600">상세 주소</p>
                      <Input
                        label={<div className="text-T5 mb-5 text-gray-400"></div>}
                        placeholder="상세주소를 입력해주세요."
                        onChange={(e) => {
                          setUserDetailAddress(e.target.value);
                        }}
                        value={userDetailAddress}
                      />
                    </div>
                  </>
                ) : (
                  <div className="absolute left-0 w-full">
                    <PostCode setAddress={setUserAddress} setAddressPage={setAddressPage} />
                  </div>
                )}
              </div>
            }
            footer={{
              defaultButton: {
                onClick: () => {
                  postAddress();
                },
                text: '완료',
                color: BOXBUTTON_COLOR.BLACK,
              },
            }}
          />
        </div>
      )}
    </>
  );
};
