import { useState } from 'react';

import { LogEvent } from '@analytics';
import { useGetUser } from '@db/collections';
import { NewBoxButton } from 'design-system';
import { replaceCurrentURLState } from 'shared-values';

import { Loading } from '@components';
import { verification } from '@components/MarketingAgreementBottomSheet/hooks/use-verification-agree';

import { useSetUpJquery } from '@hooks';

export const VerificationButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useGetUser<true>();

  useSetUpJquery();

  const onClickVerification = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      if (!user) {
        throw new Error();
      }

      LogEvent.매출.verificationClick();
      await verification(user, () => {
        replaceCurrentURLState({ isVerified: 'true' });
        window.location.reload();
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <NewBoxButton
      styles="filled-black"
      size="small"
      onClick={onClickVerification}
      label={'본인인증'}
    />
  );
};
