import { NewIcon } from 'design-system';

interface Props {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

export const CalenderHeader = (props: Props) => {
  const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } =
    props;

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return (
    <section className="flex items-center justify-between pb-16">
      <p className="text-new-gray-900 text-new-Section-Title">
        {year}년 {month}월
      </p>
      <article className="flex items-center justify-center gap-24">
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <NewIcon
            icon="arrowleft-outlined"
            size={20}
            className={prevMonthButtonDisabled ? 'fill-new-gray-400' : 'fill-new-gray-900'}
          />
        </button>
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <NewIcon
            icon="arrowright-outlined"
            size={20}
            className={prevMonthButtonDisabled ? 'fill-new-gray-900' : 'fill-new-gray-400'}
          />
        </button>
      </article>
    </section>
  );
};
