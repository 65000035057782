import { Dispatch, SetStateAction } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';

import { useWindowSize } from '@hooks';

interface PostCodeProps {
  setAddress: Dispatch<SetStateAction<string>>;
  setAddressPage: Dispatch<SetStateAction<PurchasePage | null>>;
}

export const PostCode = ({ setAddressPage, setAddress }: PostCodeProps) => {
  const { height: innerHeight } = useWindowSize();

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setAddress(fullAddress);
    setAddressPage('address');
  };
  return (
    <div>
      <DaumPostcodeEmbed
        style={{ width: '100%', height: innerHeight }}
        onComplete={handleComplete}
      />
    </div>
  );
};
