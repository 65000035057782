import classNames from 'classnames';
import { Spacer } from 'design-system';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  selectedSchedule: string | null;
  dateType: 'single' | 'range';
  hasHeight?: boolean;
}

export const SelectedScheduleIndicator = (props: Props) => {
  const { selectedSchedule, dateType, hasHeight = true } = props;

  return (
    <>
      {hasHeight && <Spacer className='h-40' />}
      <AnimatePresence>
        <motion.div
          className={classNames('absolute bottom-[0px] left-0 z-0 h-[40px] w-full')}
          initial={{ y: 200 }}
          animate={{ y: 0 }}
          exit={{ y: 200 }}
          transition={{ type: 'spring', bounce: 0, mass: 0.1 }}>
          <p
            className='bg-new-black-75 text-new-white
             flex w-full items-center gap-8 px-16 py-8'>
            <span className='text-new-white-75 text-new-Body2-medium'>
              {dateType === 'single' ? '선택한 날짜' : '선택한 기간'}
            </span>
            <span className='text-new-Body1-medium'>{selectedSchedule}</span>
          </p>
        </motion.div>
      </AnimatePresence>
    </>
  );
};
