import { Dispatch, SetStateAction } from 'react';

import { BottomSheet, NewIcon, Spacer, useOverlay } from 'design-system';

import { PrivateTerm } from '@templates/Terms/PrivateTerm';

import { PrivateInfoProvideTerm, RefundPolicyBanner } from './components';

const CheckList = [
  {
    type: 'info',
    text: '[필수] 개인정보 제 3자 제공',
  },
  {
    type: 'private',
    text: '[필수] 개인정보 수집 및 이용',
  },
  {
    type: 'refund',
    text: '[필수] 취소 및 환불 규정 동의',
  },
] as const;

type CheckListType = Record<(typeof CheckList)[number]['type'], boolean>;
interface CheckBoxProps {
  setChecked: Dispatch<SetStateAction<CheckListType>>;
  checked: CheckListType;
  isAllChecked: boolean;
  academy: Academy;
  lessonInfo: LessonInfo;
}

const getBottomSheetContent = (type: 'info' | 'private' | 'refund' | null, academyName: string) => {
  switch (type) {
    case 'info':
      return <PrivateInfoProvideTerm academyName={academyName} />;
    case 'private':
      return (
        <div className="h-[500px]">
          <PrivateTerm />
        </div>
      );
    default:
      break;
  }
};

export const PurchaseAgreement = ({
  setChecked,
  checked,
  isAllChecked,
  academy,
  lessonInfo,
}: CheckBoxProps) => {
  const overlay = useOverlay();
  const { name: academyName, paymentType } = academy;
  const is전액결제 = paymentType === 'entire';

  const is면허캠프 = lessonInfo.lessonCode === 'TWO_AUTO_CAMP';

  const handleTermsBottomSheetClick = (type: 'info' | 'private' | 'refund' | null) => {
    if (!type || type === 'refund') return null;

    overlay.open(({ isOpen, close }) => {
      return (
        <BottomSheet
          isOpen={isOpen}
          onClose={() => {
            close();
          }}
          header={{
            title:
              type === 'info'
                ? '개인정보 제3자 제공'
                : type === 'private'
                ? '개인정보처리방침'
                : '',
          }}
          main={<article className="h-[520px]">{getBottomSheetContent(type, academyName)}</article>}
        />
      );
    });
  };

  return (
    <>
      <Spacer className="h-32" />
      <h4 className="text-new-Sub-Title text-new-gray-900">
        <span className="text-new-DTRed-400 text-new-Sub-Title">*</span>결제 동의
      </h4>
      <Spacer className="h-20" />
      <div
        className="flex cursor-pointer flex-row items-center gap-8"
        onClick={() => {
          if (isAllChecked) {
            setChecked({
              info: false,
              private: false,
              refund: false,
            });
          } else {
            setChecked({
              info: true,
              private: true,
              refund: true,
            });
          }
        }}
      >
        {isAllChecked ? (
          <NewIcon icon="multiple-picker-active" />
        ) : (
          <NewIcon icon="multiple-picker-inactive" />
        )}
        <p className="text-new-Body1-bold text-new-gray-900">약관 전체 동의</p>
      </div>
      <div className="flex flex-col gap-12 px-32 pt-12">
        {CheckList.map((check, index) => {
          const { type, text } = check;
          const isChecked = checked[type];
          return (
            <div
              key={index}
              className="flex w-fit cursor-pointer flex-row items-center gap-8"
              onClick={() =>
                setChecked((prev) => {
                  const next = { ...prev };
                  next[type] = !next[type];
                  return next;
                })
              }
            >
              {isChecked ? (
                <NewIcon icon="multiple-picker-active" />
              ) : (
                <NewIcon icon="multiple-picker-inactive" />
              )}
              <div
                className="text-new-Body2-medium"
                onClick={() => {
                  handleTermsBottomSheetClick(type);
                }}
              >
                {text}
              </div>
            </div>
          );
        })}
      </div>
      <Spacer className="h-20" />
      <RefundPolicyBanner is전액결제={is전액결제} is면허캠프={is면허캠프} />
      <Spacer className="h-80" />
    </>
  );
};
