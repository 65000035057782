import { ComponentProps } from 'react';

import { LogEvent } from '@analytics/LogEvent/LogEvent';
import { useGetUser } from '@db/collections';
import {
  BOXBUTTON_COLOR,
  BottomSheet,
  NewBoxButton,
  NewIcon,
  useOverlay,
  useToast,
} from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { KAKAO_REDIRECT_URI } from 'shared-values';

import { useApplicationFunnel } from '@templates/Application/hooks';
import { loginStateId, saveLoginState } from '@templates/Login';

import { useABTest } from '@hooks';
import { Funnel } from '@hooks/use-funnel';
import { usePageQuery } from '@hooks/use-handle-url';
import { getDeviceByUserAgent } from '@utils/Common';

import { PurchaseButton } from './PurchaseButton';

interface PurchaseBottomProps {
  purchaseCondition: ComponentProps<typeof PurchaseButton>['purchaseCondition'];
  isKeyInPurchase: boolean;
}

const LessonInfo = () => {
  const { discountResult, lessonInfo } = useApplicationFunnel<'Purchase'>();
  const { lessonName, lessonDiscountPrice, lessonTotalPrice } = lessonInfo;

  const totalDiscountPrice = discountResult.할인금 + lessonDiscountPrice;
  return (
    <>
      <div className="w-full pt-5">
        <div className="text-Body4 leading-[22.4px] text-gray-600">{lessonName}</div>
        <div>
          {totalDiscountPrice > 0 ? (
            <div className="flex items-center gap-4">
              <div className="text-Body1 text-new-DTRed-400 leading-[25.6px]">
                {(discountResult.원금 + lessonDiscountPrice - totalDiscountPrice).toLocaleString()}
                원
              </div>
              <span className="text-[13px] text-gray-400 line-through	">
                {(discountResult.원금 + lessonDiscountPrice).toLocaleString()}원
              </span>
            </div>
          ) : (
            <div className="text-Body1 leading-[25.6px]">
              {discountResult.최종금.toLocaleString()}원
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const PurchaseBottom = ({ purchaseCondition, isKeyInPurchase }: PurchaseBottomProps) => {
  const { user } = useGetUser();
  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();
  const {
    discounts,
    discountResult,
    lessonInfo,
    academy,
    visitorInfo,
    shuttleBusPreference,
    selectedTime,
    selectedRange,
    selectedCoupons,
  } = useApplicationFunnel<'Purchase'>();

  // 카카오로 바로 로그인 / 로그인 페이지로 이동 AB 테스트
  // https://analytics.google.com/analytics/web/?authuser=1#/analysis/p335020101/edit/r92ioP_ST2O8m7IZknFw6Q에서 결과를 확인할 수 있음.
  const [loginNavigate] = useABTest('LOGIN_NAVIGATE');

  const { openToast } = useToast();
  const canPurchase = Object.values(purchaseCondition).every((condition) => condition.value);

  const overlay = useOverlay();

  const router = useRouter();

  if (!user)
    return (
      <Funnel.Bottom className="flex bg-white">
        <LessonInfo />
        <div
          onClick={(e) => {
            const findCondition = Object.values(purchaseCondition).find(
              (condition) => !condition.value,
            );

            if (findCondition) {
              findCondition.ref?.scrollIntoView({ behavior: 'smooth' });

              openToast({
                title: findCondition.message,
              });
              e.preventDefault();
              return;
            }
          }}
        >
          <NewBoxButton
            label="결제하기"
            styles="filled-red"
            state={canPurchase ? 'active' : 'inactive'}
            onClick={() => {
              overlay.open(({ isOpen, close }) => {
                return (
                  <BottomSheet
                    isOpen={isOpen}
                    onClose={close}
                    header={{
                      title: '예약을 위해 추가 정보가 필요해요.',
                      subTitle: '지금까지 입력한 정보는 그대로 유지돼요.',
                    }}
                    main={
                      <div className="flex w-full items-center justify-center">
                        <Image src="/need-login.png" width={96} height={96} alt="로그인 필요" />
                      </div>
                    }
                    footer={{
                      defaultButton: {
                        onClick: async () => {
                          if (loginNavigate === 'YES') {
                            router.push({
                              pathname: '/login',
                              query: {
                                redirectUrl: window.location.pathname + window.location.search,
                              },
                            });
                            return;
                          }
                          await saveLoginState(
                            {
                              redirectUrl: window.location.pathname + window.location.search,
                            } as LoginState,
                            loginStateId,
                          );
                          await router.replace(
                            `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_KAKAO_LOGIN_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code&state=${loginStateId}`,
                          );

                          close();
                        },
                        text:
                          loginNavigate === 'YES' ? (
                            '3초만에 간편 로그인하기'
                          ) : (
                            <div className="flex items-center gap-2">
                              <NewIcon icon="kakao-20" />
                              카카오로 시작하기
                            </div>
                          ),
                        color: BOXBUTTON_COLOR.DTYellow,
                      },
                    }}
                  />
                );
              });
            }}
          />
        </div>
      </Funnel.Bottom>
    );

  const {
    id: academyId,
    location: { bubAddress: academyLocation },
    name: academyName,
    phoneNum: academyPhoneNum,
    submallInfo: { subCoNo: academySubCoNo, subMid: academySubMid } = { subCoNo: '', subMid: '' },
    smallmallInfo: { smbsRank: academySmallRank } = { smbsRank: '' },
    paymentType,
    isSimulation,
    images,
  } = academy;

  const receipt: Omit<ReceiptData, 'id'> = {
    academyId,
    academySubCoNo,
    academySubMid,
    academySmallRank,
    academyLocation: academyLocation ?? '',
    academyName,
    academyPhoneNum: academyPhoneNum ?? '',
    image: images[0],
    deposit: discountResult ? discountResult.최종금 : 0,
    selectedTimes: selectedTime ? [selectedTime.get()] : [],
    selectedRange: selectedRange ? selectedRange.get() : null,
    userId: user.uid ?? '',
    userName: user.realName ?? user.name,
    userPhoneNum: user.phoneNum,
    userBirthday: user.birthday ?? '',
    userAddress: user.address ? user.address : '',
    userDetailAddress: user.detailAddress ? user.detailAddress : '',
    visitorName: visitorInfo.name,
    visitorBirthday: visitorInfo.birthday,
    visitorPhoneNum: visitorInfo.phoneNum.replaceAll('-', ''),
    shuttleBusPreference: shuttleBusPreference,
    device: getDeviceByUserAgent(),
    paymentType,
    isSimulation,
    applicationMode,
    ...lessonInfo,
  };

  return (
    <Funnel.Bottom className="flex bg-white">
      <LessonInfo />
      <PurchaseButton
        purchaseCondition={purchaseCondition}
        receipt={receipt}
        addressString={user.address ? user.address + user.detailAddress : ''}
        isTogetherEvent={applicationMode === 'together-purchase'}
        isKeyInPurchase={isKeyInPurchase}
        discountPrice={discountResult ? discountResult?.할인금 : 0}
        totalPrice={discountResult ? discountResult.최종금 : 0}
        운전선생할인금={discountResult ? discountResult.운전선생할인금 : 0}
        시뮬레이션할인금={discountResult ? discountResult.시뮬레이션할인금 : 0}
        onClickPurchase={(purchaseMethod) => {
          const lesson = academy.selectLesson({
            code: lessonInfo.lessonCode,
            periodText: lessonInfo.lessonPeriodText,
            registType: lessonInfo.lessonRegistType as RegistType,
          });

          LogEvent.매출.$결제시작(academy, lesson, applicationMode, purchaseMethod, discounts);
        }}
        discounts={discounts}
        selectedCoupons={selectedCoupons}
      />
    </Funnel.Bottom>
  );
};
