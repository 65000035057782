import { getHighestCouponWithPrice } from '@db/collections';

const DiscountSources: DiscountSource[] = ['dt', 'simulation'];

// 이 함수는 쿠폰 배열과 수업 가격을 입력받아, 특정 조건을 만족하는 최고의 쿠폰들을 선택합니다.
export const selectBestCoupons = (coupons: CouponCode[], lessonPrice: number) => {
  // 주어진 쿠폰 유형에 따라 최고의 쿠폰을 선택하는 함수
  const selectCouponByType = (source: DiscountSource) =>
    coupons
      .filter((coupon) => coupon.discount.discountSource === source)
      .map((coupon) => getHighestCouponWithPrice([coupon], lessonPrice))
      .filter((coupon) => coupon !== undefined)[0];

  // 각 쿠폰 유형에 대해 최고의 쿠폰을 선택하고, 유효한 쿠폰만 배열로 반환
  return DiscountSources.map(selectCouponByType).filter((coupon) => coupon !== undefined);
};
