import { useMemo, useState } from 'react';

import { BOXBUTTON_COLOR, BottomSheet } from 'design-system';

import { CouponCard } from '@components';

export const CouponsSelect = ({
  isOpen,
  close,
  coupons,
  initialSelectedCoupon,
  lessonPeriodText,
  onSelectedCoupon,
}: {
  isOpen: boolean;
  close: () => void;
  coupons: CouponCode[];
  initialSelectedCoupon: CouponCode;
  lessonPeriodText: string;
  onSelectedCoupon: (coupon: CouponCode) => void;
}) => {
  const [selectedCoupon, setSelectedCoupon] = useState(initialSelectedCoupon);

  const keyedCoupons = useMemo(
    () => coupons.map((coupon) => ({ coupon, id: crypto.randomUUID() })),
    [coupons],
  );

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={close}
      header={{
        title: '받은 쿠폰',
      }}
      main={
        <div className='flex max-h-[330px] flex-col gap-10 overflow-y-scroll'>
          {keyedCoupons.map(({ id, coupon }) => {
            const { source } = coupon;

            const isSelected = source === selectedCoupon.source;

            const isDisabled = !coupon.discount.canApply({
              periodText: lessonPeriodText,
            });

            return (
              <CouponCard
                key={id}
                coupon={coupon}
                onClickCoupon={() => {
                  setSelectedCoupon(coupon);
                }}
                isSelected={isSelected}
                isDisabled={isDisabled}
              />
            );
          })}
        </div>
      }
      footer={{
        defaultButton: {
          text: '이 쿠폰으로 적용하기',
          color: BOXBUTTON_COLOR.DTYellow,
          onClick: () => {
            onSelectedCoupon(selectedCoupon);
          },
        },
      }}
    />
  );
};
