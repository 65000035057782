import { useEffect, useRef, useState } from 'react';

import { useGetTimetable, 당일예약가능학원 } from 'database';
import { ko } from 'date-fns/locale';
import { Spacer } from 'design-system';
import dynamic from 'next/dynamic';
import { Tomorrow, addDays, formatDate } from 'shared-values';

import { Loading } from '@components';
import { FormStyle } from '@templates/Application/components';
import { useApplicationFunnel } from '@templates/Application/hooks';

import { BlockAcademyIds } from '../../DateSelect';
import { CalenderHeader } from '../CalenderHeader';
import { TimeTable } from './components';
import { getHighlightDates } from './utils';

export type TimetableData = Record<string, SelectedTime[]>;

const DatePicker = dynamic(() => import('react-datepicker').then((v) => v.default), {
  ssr: false,
});

const today = new Date();

interface Props {
  isFromOnSiteEvent?: boolean;
}

function getDatesBetween(startDate: Date, endDate: Date) {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

const BlockDates = getDatesBetween(new Date(), new Date('2024-12-31'));

export const TimeSelectionCalendar = ({ isFromOnSiteEvent }: Props) => {
  const {
    lessonInfo,
    academy,
    selectedTime: funnelSelectedTime,
    setSelectedTime: setFunnelSelectedTime,
  } = useApplicationFunnel<'DateSelect'>();
  const isAnimationAlreadyExecuted = useRef(false);

  const { id: academyId, type } = academy;
  const isGeneralAcademy = type === 'general-academy';

  const MIN_DATE = isFromOnSiteEvent || 당일예약가능학원.includes(academyId) ? today : Tomorrow;

  const MAX_DATE = addDays(today, 42);

  const [timetableData, setTimetableData] = useState<{
    availableDays: Record<string, SelectedTime[]>;
    excludeDates: Date[];
  }>({
    availableDays: {},
    excludeDates: [],
  });

  const { availableDays, excludeDates } = timetableData;
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const {
    data: timetable,
    isLoading: isTimetableLoading,
    isError: isTimetableError,
  } = useGetTimetable(academyId as string);

  useEffect(() => {
    onMonthChange(MIN_DATE);
    setSelectedDate(null);
    setFunnelSelectedTime(null);
  }, [timetable]);

  const onMonthChange = (date: Date) => {
    if (!timetable) return;
    setSelectedDate(null);

    const { lessonCode } = lessonInfo;
    const availableDays = timetable.getAvailableDaysInMonth({
      lessonCode,
      date,
    });

    const excludeDates = timetable.getExcludeDates({ date, availableDays, isFromOnSiteEvent });
    setTimetableData({ availableDays, excludeDates });
  };

  const getTimeList = (selectedDate: Date | null) => {
    if (!selectedDate) return [];

    const currentStringDate = formatDate(selectedDate, 'YYYY-MM-DD');
    return availableDays[currentStringDate];
  };

  if (isTimetableLoading) return <Loading />;
  if (isTimetableError) return <div>잘못된 접근입니다</div>;

  const isTraining = lessonInfo.lessonCode.toUpperCase().includes('TRAINING');

  return (
    <>
      <FormStyle
        title="원하는 날짜를 선택해 주세요."
        description={`선택한 날짜에 ${
          isGeneralAcademy && !isTraining ? '장내기능교육' : '학과교육'
        }이 진행됩니다.`}
      >
        <div className="rounded-md">
          <DatePicker
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <CalenderHeader
                date={date}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            highlightDates={getHighlightDates(
              selectedDate,
              !isFromOnSiteEvent ? excludeDates : undefined,
            )}
            selectsStart
            onMonthChange={onMonthChange}
            excludeDates={BlockAcademyIds.includes(academyId) ? BlockDates : excludeDates}
            dateFormatCalendar={'yy.MM'}
            locale={ko}
            minDate={MIN_DATE}
            maxDate={MAX_DATE}
            startDate={today}
            onChange={(date: Date) => {
              setSelectedDate(date);
              setFunnelSelectedTime(null);
            }}
            inline
          />
        </div>
        {selectedDate && (
          <>
            <Spacer className="h-40" />
            <hr className="border-new-gray-100 -mx-16 border-4" />
            <TimeTable
              timeList={getTimeList(selectedDate)}
              selectedTime={funnelSelectedTime}
              setSelectedTime={setFunnelSelectedTime}
              isAnimationAlreadyExecuted={isAnimationAlreadyExecuted}
            />
          </>
        )}
      </FormStyle>
    </>
  );
};
