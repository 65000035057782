import { useEffect, useRef, useState } from 'react';

import { SimulationLesson } from '@db/collections';
import { AppBarItem, NewIcon } from 'design-system';
import { isEmpty } from 'lodash-es';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { replaceCurrentURLState, sleep } from 'shared-values';

import { BackLink } from '@components/Layout/Floating/GlobalAppBar/components';
import { isGlobalLinkFromAcademy } from '@pages/academy/[id]/application';
import { FormStyle } from '@templates/Application/components';
import { useApplicationFunnel } from '@templates/Application/hooks';

import { usePreventBack } from '@hooks';
import { Funnel } from '@hooks/use-funnel';
import { usePageQuery } from '@hooks/use-handle-url';

import { Boarding } from '../Boarding';
import { RegistTypeCard } from './components';
import { AcademyLinkCards } from './components/AcademyLinkCards';

const ACADEMY_TITLE = '어떤 교육을 원하시나요?';
const SIMULATION_TITLE = '어떤 서비스를 원하시나요?';

const WAITING_TIME = 600;

const isLessonsExist = (lessons: (Lesson | SimulationLesson)[], type: RegistType) => {
  return lessons
    .filter((lesson) => lesson.isOpen)
    .some((lesson) => {
      if (lesson instanceof SimulationLesson) {
        return lesson.registType === type;
      } else {
        return type === 'training'
          ? lesson.licenseCode === 'TRAINING'
          : lesson.licenseCode !== 'TRAINING';
      }
    });
};

let isActivated = false;
const useGetAcademyRegistType = () => {
  const { academy } = useApplicationFunnel<'RegistTypeSelect'>();

  return [
    {
      type: 'license',
      title: '면허를 따고 싶어요.',
      description: '아직 면허가 없는 분이라면?',
      // 시뮬레이션, 운전학원이 이미지가 달라져야 하는지는 확인 필요
      image: { src: '/personal-funnel/IdCard.svg', alt: 'license-img' },
    },
    ...(academy.lessons.some((l) => l.code.includes('VISIT_TRAINING'))
      ? [
          {
            type: 'visit-training',
            title: '방문 연수를 받고 싶어요.',
            description: '장롱 면허 보유자라면?',
            image: { src: '/personal-funnel/Car.svg', alt: 'car-img' },
          },
        ]
      : [
          {
            type: 'training',
            title: '연수를 받고 싶어요.',
            description: '장롱 면허 보유자라면?',
            image: { src: '/personal-funnel/Car.svg', alt: 'car-img' },
          },
        ]),
  ] as {
    type: RegistType;
    title: string;
    description: string;
    image: { src: string; alt: string };
  }[];
};

const useGetIsLinkBoarding = () => {
  const [isLinkBoarding, setIsLinkBoarding] = useState(true);
  const router = useRouter();
  const { isLinkFromAcademy, fromOnsiteEvent, applicationMode } = router.query;

  useEffect(() => {
    // 4초 후에 boarding 페이지로 닫기
    const tick = setTimeout(() => {
      setIsLinkBoarding(false);
      isActivated = true;
    }, 4000);
    return () => clearTimeout(tick);
  }, []);

  useEffect(() => {
    if (isEmpty(router.query) || (isEmpty(isLinkFromAcademy) && isEmpty(fromOnsiteEvent))) return;
    replaceCurrentURLState({
      applicationMode: fromOnsiteEvent ? 'onsite-event' : 'external-academy-link',
    });
  }, [router.query]);

  return {
    isLinkBoarding:
      isLinkBoarding &&
      Boolean(isGlobalLinkFromAcademy || isLinkFromAcademy || fromOnsiteEvent) &&
      !isActivated,
    isLinkFromAcademy: isGlobalLinkFromAcademy || isLinkFromAcademy,
  };
};

const VisitTrainingCard = ({ type, children }: { type: RegistType; children: React.ReactNode }) => {
  if (type === 'visit-training')
    return (
      <div className="w-full">
        <Link href="/training/reservation">
          <div className="pointer-events-none">{children}</div>
        </Link>
      </div>
    );

  return <div className="w-full ">{children}</div>;
};

const PreventBack = () => {
  usePreventBack({
    title: '예약을 그만두시겠어요?',
    body: '진행 중인 정보를 잃게 됩니다.',
  });
  return <></>;
};

export const RegistTypeSelect = () => {
  const { goNext, selectedRegistType, academy, setSelectedRegistType } =
    useApplicationFunnel<'RegistTypeSelect'>();
  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();

  const { isSimulation, lessons } = academy;
  const { isLinkBoarding, isLinkFromAcademy } = useGetIsLinkBoarding();
  const router = useRouter();
  const academyRegistType = useGetAcademyRegistType();

  const isSelected = useRef(false);

  const seletedRegist = async (type: RegistType) => {
    if (isSelected.current) return;
    isSelected.current = true;
    setSelectedRegistType(type);
    await sleep(WAITING_TIME);
    goNext();
  };

  return (
    <div className="relative">
      {applicationMode !== 'together-select' && <PreventBack />}
      <Funnel.Header
        text="예약"
        leftItem={
          isLinkFromAcademy ? (
            <div />
          ) : (
            <BackLink backRedirectUrl={`/academy/${router.query.id}`}>
              <AppBarItem
                item={<NewIcon icon="arrowline-outlined" size={24} className="fill-new-gray-900" />}
              />
            </BackLink>
          )
        }
      />
      {isLinkBoarding ? (
        <Boarding />
      ) : (
        <FormStyle
          title={isSimulation ? SIMULATION_TITLE : ACADEMY_TITLE}
          description={
            <p className="text-new-Body1-medium text-new-gray-500">
              <span className="text-new-DTPurple-500">맞춤 운전 교육을 </span>
              제공해드려요.
            </p>
          }
        >
          <section className="flex gap-16">
            {academyRegistType.map((item, index) => {
              const { title, description, image, type } = item;

              return (
                <VisitTrainingCard type={type} key={`RegistTypeCard-${index}`}>
                  <RegistTypeCard
                    isInactive={!isLessonsExist(lessons, type)}
                    inactiveMessage={`해당 ${isSimulation ? '연습장' : '학원'}은 ${
                      type === 'license' ? '면허 취득' : '운전 연수'
                    } ${isSimulation ? '이용권을 판매하지' : '수업이 준비되어 있지'} 않습니다.`}
                    isSelected={selectedRegistType === type}
                    title={title}
                    description={description}
                    image={image}
                    onClick={() => seletedRegist(type)}
                  />
                </VisitTrainingCard>
              );
            })}
          </section>
          {isLinkFromAcademy && <AcademyLinkCards />}
        </FormStyle>
      )}
    </div>
  );
};
